import React from 'react';
import PropTypes from 'prop-types';
import BlogPostBase from '@components/BlogPost';
import * as styles from './BlogPost.module.scss';
import classNames from 'classnames';

import Layout from "@envy/Layout";
import Footer from "@envy/Footer";
import ContactForm from "@envy/ContactForm";
import Seo from "@components/seo";
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const BlogPost = ({ data }) => {
  const page = {
    ...data.prismicNewsPost.data,
    id: data.prismicNewsPost.id,
    tags: data.prismicNewsPost.tags
  };

  return (
    <Layout transparentHeader={false}>
      <Seo title={`${page.title.text} | Envy by Gomes`} description={page.meta_description && page.meta_description.text} />
      <BlogPostBase styles={styles} page={page} />
      <div className={classNames(styles.dark, styles.bottomPattern)}>
        <ContactForm />
        <div className={styles.relativeWrapper} style={{ padding: 0 }}>
          <Footer style={{ marginTop: '104px' }} />
        </div>
        <div className={classNames(styles.sidelines)} />
      </div>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query EnvyBlogPost($id: String!) {
    prismicNewsPost(id: { eq: $id }) {
      _previewable
      tags
      id
      data {
        featured
        title {
          text
        }
        intro_text {
          text
        }
        author {
          text
          html
        }
        external_link {
          url
          target
        }
        meta_description {
          text
        }
        author_image {
          url
        }
        main_image {
          url
        }
        small_image {
          url
        }
        body {
          ... on PrismicNewsPostDataBodyRichText {
            id
            slice_type
            items {
              text {
                raw
              }
            }
          }
          ... on PrismicNewsPostDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              image {
                url
              }
            }
          }
          ... on PrismicNewsPostDataBodyQuote {
            id
            slice_type
            primary {
              quote {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogPost);

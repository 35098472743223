// extracted by mini-css-extract-plugin
export var relativeWrapper = "Footer-module--relativeWrapper--pFOEf";
export var decor = "Footer-module--decor--1bg_4";
export var container = "Footer-module--container--2Vlkw";
export var row = "Footer-module--row--wj-aI";
export var lastRow = "Footer-module--lastRow--3EAKW";
export var wrapper = "Footer-module--wrapper--riak7";
export var social = "Footer-module--social--1heBH";
export var logo = "Footer-module--logo--3txu1";
export var contact = "Footer-module--contact--2EwBM";
export var copyright = "Footer-module--copyright--2Cirz";
export var programs = "Footer-module--programs--PsEWT";
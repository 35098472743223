import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from "@home/Header";
import ContactForm from "@home/ContactForm";
import Footer from '@home/Footer';

const { optionTypes } = ContactForm;
const initialState = {
  contactFormOption: optionTypes.INFO,
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CONTACT_TYPE":
      if (action.contactType) {
        return {
          ...state,
          contactFormOption: action.contactType
        };
      } else if (action.contactTypeName) {

        return {
          ...state,
          contactFormOption: optionTypes[action.contactTypeName.toUpperCase()]
        };
      }
      break;
    default:
      return state;
  }
};

const store = createStore(reducer, initialState);

const Layout = ({ transparentHeader, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Provider store={store}>
      <ParallaxProvider>
        <Header
          siteTitle={data.site.siteMetadata?.title || "By Gomes"}
          transparent={transparentHeader}
          style={{}}
        />

        <main style={{ overflowX: "hidden" }}>
          <div style={{ position: "relative" }}>
            {children}
            <ContactForm />
            <Footer />
          </div>
        </main>
      </ParallaxProvider>
    </Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transparentHeader: PropTypes.bool,
};

Layout.defaultProps = {
  transparentHeader: true,
};

export default Layout;

import React, { useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import "animate.css";
import HamburgerButton from "@components/HamburgerButton";
import classNames from "classnames";

const reducer = (state, action) => {
  switch (action.type) {
    case "SCROLLING_DOWN":
      return { state: "hidden" };
    case "SCROLLING_UP":
      return { state: "mini" };
    case "AT_TOP":
      return { state: action.transparent ? "normal" : "mini" };
    case "EXPAND_HEADER":
      return { state: "expanded", lastState: state.state };
    case "UNEXPAND_HEADER":
      return { state: state.lastState };
    default:
      throw new Error("Invalid action type");
  }
};

const Header = props => {
  const { transparent, styles } = props;
  const expandedMenu = useRef(null);
  const lastScrollY = useRef(0);
  // Used to prevent scroll from changing state when expanded
  const expanded = useRef(false);

  let defaultState = "mini";
  if (transparent == true) {
    defaultState = "normal";
  }
  const [header, dispatch] = useReducer(reducer, { state: defaultState });

  let containerClasses = classNames(styles.container, {
    [styles.expanded]: header.state === "expanded",
    [styles.mini]: header.state === "mini",
    [styles.hidden]: header.state === "hidden",
  });

  const handleOpen = () => {
    const classList = expandedMenu.current?.classList;
    classList.remove("animate__zoomOut");
    classList.add("animate__zoomIn", styles.zoomedIn);

    dispatch({ type: "EXPAND_HEADER" });
    expanded.current = true;

    const linkList = Array.from(document.getElementsByClassName("link"));
    linkList.forEach(el => el.classList.toggle("animate__fadeInUp"));
  };

  const handleClose = () => {
    const classList = expandedMenu.current?.classList;
    classList.remove("animate__zoomIn");
    classList.add("animate__zoomOut");

    dispatch({ type: "UNEXPAND_HEADER" });
    expanded.current = false;

    const linkList = Array.from(document.getElementsByClassName("link"));
    linkList.forEach(el => el.classList.toggle("animate__fadeInUp"));
  };

  const handleScroll = () => {
    if (expanded.current) return;

    const scrollPos = window.scrollY;
    let scrollDirection = lastScrollY.current < scrollPos ? "down" : "up";
    lastScrollY.current = scrollPos;

    if (scrollPos === 0) {
      scrollDirection = "top";
    }

    switch (scrollDirection) {
      case "down":
        return dispatch({ type: "SCROLLING_DOWN", transparent: transparent });
      case "up":
        return dispatch({ type: "SCROLLING_UP", transparent: transparent });
      case "top":
        return dispatch({ type: "AT_TOP", transparent: transparent });
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    window.addEventListener('hashchange', handleClose, false);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", handleClose);
    };
  }, []);

  return (
    <div className={containerClasses}>
      <div className={styles.headerContent}>
        <HamburgerButton
          onOpen={handleOpen}
          onClose={handleClose}
          isClosed={!expanded.current}
          styles={styles}
        />
        {props.headerContent(header)}
      </div>
      <div
        ref={expandedMenu}
        className={classNames(
          styles.expandedContent,
          "animate__animated animate__faster"
        )}
      >
        {props.expandedContent(handleClose)}
      </div>
    </div>
  );
};

Header.propTypes = {
  transparent: PropTypes.bool.isRequired,
  headerContent: PropTypes.func.isRequired,
  expandedContent: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
};

Header.defaultProps = {
  transparent: true,
};

export default Header;

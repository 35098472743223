import React from 'react';
import FacebookIcon from '@images/arbol/Footer/facebook.svg';
import InstagramIcon from '@images/arbol/Footer/instagram.svg';
import LogoDark from '@images/arbol/Footer/logo-dark.svg';
import LiveWorkPlay from '@images/arbol/Footer/live-work-play.svg';
import AccessibilityIcon from '@images/arbol/Footer/accessibility.svg';
import EqualityIcon from '@images/arbol/Footer/equality.svg';
import classNames from 'classnames';
import * as styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={styles.container} style={{ marginTop: '104px' }}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <div className={styles.social}>
            <a href="https://www.facebook.com/arbolbygomes" target="_blank" rel="noreferrer"><FacebookIcon /></a>
            <a href="https://www.instagram.com/arbolbygomes" target="_blank" rel="noreferrer"><InstagramIcon /></a>
          </div>
        </div>
        <div className={classNames(styles.wrapper, styles.logo)}>
          <div className={styles.logo}>
            <LogoDark />
            <LiveWorkPlay />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contact}>
            <a href="tel:9735543475">973 554 3475</a>
            <a href="mailto:arbol@bygomes.com">ARBOL@BYGOMES.COM</a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.row, styles.lastRow)}>
        <div className={styles.wrapper} />
        <div className={styles.wrapper}>
          <p className={styles.copyright}>Copyright © {new Date().getFullYear()} Arbol by Gomes. All right reserved.</p>
        </div>
        <div className={classNames(styles.wrapper, styles.programs)}>
          <EqualityIcon />
          <AccessibilityIcon />
        </div>
      </div>
    </div>
  );
}

export default Footer;

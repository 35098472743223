// extracted by mini-css-extract-plugin
export var relativeWrapper = "Properties-module--relativeWrapper--3GfwA";
export var decor = "Properties-module--decor--J-Hl5";
export var button = "Properties-module--button--1HRjX";
export var lineAbove = "Properties-module--lineAbove--2B7sc";
export var container = "Properties-module--container--UOdt4";
export var full = "Properties-module--full--3dpCK";
export var infoBox = "Properties-module--infoBox--1I0Wd";
export var gLogo = "Properties-module--gLogo--14PUc";
export var item = "Properties-module--item--3Ov_Q";
export var navigation = "Properties-module--navigation--3DDIe";
export var position = "Properties-module--position--3lYPt";
export var arrow = "Properties-module--arrow--3ISVS";
export var next = "Properties-module--next--13o-U";
export var prev = "Properties-module--prev--17nQq";
export var imageCarousel = "Properties-module--imageCarousel--24Njz";
export var slideImage = "Properties-module--slideImage--1d7fw";
export var small = "Properties-module--small--1lOg3";
export var cardCarousel = "Properties-module--cardCarousel--2XmhR";
export var propertyCard = "Properties-module--propertyCard--3j2FD";
export var infoBlock = "Properties-module--infoBlock--2AEu8";
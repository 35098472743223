// extracted by mini-css-extract-plugin
export var relativeWrapper = "TheNeighborhood-module--relativeWrapper--1aRWe";
export var decor = "TheNeighborhood-module--decor--3dXhE";
export var hamburger = "TheNeighborhood-module--hamburger--3WtY9";
export var units = "TheNeighborhood-module--units--2Vucd";
export var reactCalendarMonthViewDays = "TheNeighborhood-module--react-calendar__month-view__days--k5E5K";
export var reactCalendarTile = "TheNeighborhood-module--react-calendar__tile--2-EIU";
export var reactCalendarTileActive = "TheNeighborhood-module--react-calendar__tile--active--1WVtE";
export var container = "TheNeighborhood-module--container--2sBdR";
export var carousel = "TheNeighborhood-module--carousel--2gLib";
export var cardCarousel = "TheNeighborhood-module--cardCarousel--XJkxB";
export var cardWrapper = "TheNeighborhood-module--cardWrapper--1TLE5";
export var image = "TheNeighborhood-module--image--wAVmm";
export var infoBlock = "TheNeighborhood-module--infoBlock--3HN_V";
export var arrow = "TheNeighborhood-module--arrow--_dkEa";
export var backButton = "TheNeighborhood-module--backButton--1q4ge";
export var nextButton = "TheNeighborhood-module--nextButton--2j6Ow";
export var infoCarousel = "TheNeighborhood-module--infoCarousel--1-Hpq";
export var infoWrapper = "TheNeighborhood-module--infoWrapper--2g4gK";
export var info = "TheNeighborhood-module--info--zKLWf";
export var name = "TheNeighborhood-module--name--1gqik";
export var description = "TheNeighborhood-module--description--1BoME";
export var address = "TheNeighborhood-module--address--3OOZF";
export var divider = "TheNeighborhood-module--divider--320hi";
export var directions = "TheNeighborhood-module--directions--2HaDV";
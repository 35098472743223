import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './ArrowButton.module.scss';
import ArrowButton from './ArrowButton';

function NextButton(props) {
  return (
    <div
      className={classNames(styles.nextButton, props.className, {
        [styles.small]: props.small
      })}
      style={props.style}
      onClick={props.onClick}
    >
      <ArrowButton {...props} />
    </div>
  );
}

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  small: PropTypes.bool
};

NextButton.defaultProps = {
  style: {},
  className: '',
  small: false
};

export default NextButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';
import BaseContactForm from '@components/ContactForm';
import * as styles from './ContactForm.module.scss';

const dropdownTypes = {
  TALK: 'let\'s talk',
  TOUR: 'take a tour'
};

const dropdownOptions = [
  dropdownTypes.TALK,
  dropdownTypes.TOUR
];

function ContactForm() {
  const dispatch = useDispatch();

  const handleOptionClick = (e, setFormData) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      option: e.target.dataset.value
    }));

    switch (e.target.dataset.value) {
      case dropdownTypes.TALK:
        return dispatch({ type: 'SET_CONTACT_TALK' });
      case dropdownTypes.TOUR:
        return dispatch({ type: 'SET_CONTACT_TOUR' });
    }
  };

  return (
    <div className={styles.relativeWrapper}>
      <StaticImage
        className={styles.decor}
        style={{ top: '206px', right: '55%', width: '1584px', height: '1226px' }}
        src="../../../images/arbol/landing-page/decor12.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '9px', right: '10%', width: '774px', height: '1442px', zIndex: 1 }}
        src="../../../images/arbol/landing-page/decor13.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '127px', left: '-17%', width: '2342px', height: '1064px' }}
        src="../../../images/arbol/landing-page/decor14.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '-253px', left: '84%', width: '1225px', height: '1367px' }}
        src="../../../images/arbol/landing-page/decor15.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '-753px', left: '81%', width: '2291px', height: '2281px' }}
        src="../../../images/arbol/ContactForm/ink-splash.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '-753px', left: '81%', width: '2291px', height: '2281px' }}
        src="../../../images/arbol/ContactForm/ink-splash.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <BaseContactForm
        heading="CONTACT ARBOL"
        subHeading="Hey there! Let's talk."
        name="Arbol Contact Form"
        options={dropdownOptions}
        handleOptionClick={handleOptionClick}
        styles={styles}
        subject_template="[Arbol- {option}] Form Submission from Contact Form"
        id="arbolContact"
      />
    </div>
  );
}

ContactForm.optionTypes = dropdownTypes;

export default ContactForm;

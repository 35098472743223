import React from 'react';
import { useDispatch } from 'react-redux';
import BaseContactForm from '@components/ContactForm';
import * as styles from './ContactForm.module.scss';

export const dropdownTypes = {
  INFO: 'Request Info',
  DEVELOPMENT: 'Services - Development',
  CONSTRUCTION: 'Services - Construction',
  MANAGEMENT: 'Services - Management',
  CONSULTING: 'Services - Consulting',
  INVESTMENTS: 'Services - Investments',
  EMPLOYMENT: 'Work Here'
};

const dropdownOptions = [
  dropdownTypes.INFO,
  dropdownTypes.DEVELOPMENT,
  dropdownTypes.CONSTRUCTION,
  dropdownTypes.MANAGEMENT,
  dropdownTypes.CONSULTING,
  dropdownTypes.INVESTMENTS,
  dropdownTypes.EMPLOYMENT
];

function ContactForm() {
  const dispatch = useDispatch();

  const handleOptionClick = (e, setFormData) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      option: e.target.dataset.value
    }));

    dispatch({ type: 'SET_CONTACT_TYPE', contactType: e.target.dataset.value });
  };

  return (
    <div>
      <BaseContactForm
        heading="CONTACT GOMES GROUP"
        subHeading="Hey there! Let's talk."
        options={dropdownOptions}
        handleOptionClick={handleOptionClick}
        styles={styles}
        name="Gomes Contact Form"
        subject_template="[Gomes - {option}] Form Submission from Contact Form"
        id="gomesContact"
      />
    </div>
  );
}

ContactForm.optionTypes = dropdownTypes;

export default ContactForm;

// extracted by mini-css-extract-plugin
export var button = "Banner-module--button--2CD9B";
export var lineAbove = "Banner-module--lineAbove--1jvZM";
export var relativeWrapper = "Banner-module--relativeWrapper--3ZBuW";
export var decor = "Banner-module--decor--1CY65";
export var banner = "Banner-module--banner--3QFOu";
export var image = "Banner-module--image--oVzVq";
export var arrow = "Banner-module--arrow--NChJo";
export var next = "Banner-module--next--2-zy3";
export var prev = "Banner-module--prev--34FQ8";
export var heading = "Banner-module--heading--1HopI";
export var company = "Banner-module--company--2jc9P";
export var mobile = "Banner-module--mobile--1F6wu";
export var title = "Banner-module--title--fcsBY";
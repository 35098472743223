import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function HamburgerButton({ onOpen, onClose, isClosed, styles }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    if (isOpen)
      onClose();
    else
      onOpen();

    setIsOpen(!isOpen);
  };

  const hamburgerClass = classNames(styles.hamburger, styles.hamburgerSqueeze, {
    [styles.isActive]: isOpen
  });

  useEffect(() => {
    if (isClosed !== undefined) {
      setIsOpen(!isClosed);
    }
  });

  return (
    <button className={hamburgerClass} type="button" onClick={handleClick}>
      <span className={styles.hamburgerBox}>
        <span className={styles.hamburgerInner}></span>
      </span>
    </button>
  );
}

HamburgerButton.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
  styles: PropTypes.object.isRequired
};

HamburgerButton.defaultProps = {
  isClosed: undefined
};

export default HamburgerButton;

// extracted by mini-css-extract-plugin
export var relativeWrapper = "Amenities-module--relativeWrapper--1m--2";
export var decor = "Amenities-module--decor--3gknE";
export var hamburger = "Amenities-module--hamburger--31RzU";
export var units = "Amenities-module--units--3rJ4c";
export var reactCalendarMonthViewDays = "Amenities-module--react-calendar__month-view__days--gQVMY";
export var reactCalendarTile = "Amenities-module--react-calendar__tile--1zDf9";
export var reactCalendarTileActive = "Amenities-module--react-calendar__tile--active--3HxL-";
export var container = "Amenities-module--container--15xxN";
export var textContainer = "Amenities-module--textContainer--UD0Zq";
export var carouselContainer = "Amenities-module--carouselContainer--2vg1M";
export var mobileTabs = "Amenities-module--mobileTabs--2lpuf";
export var decorLine = "Amenities-module--decorLine--Ndu77";
export var tabs = "Amenities-module--tabs--3ZXSH";
export var tab = "Amenities-module--tab--1oluY";
export var selected = "Amenities-module--selected--3-0ge";
export var backgroundImage = "Amenities-module--backgroundImage--TYrAt";
export var foregroundImage = "Amenities-module--foregroundImage--24w4-";
export var carousel = "Amenities-module--carousel--2Gelh";
export var arrow = "Amenities-module--arrow--1MKz0";
export var backButton = "Amenities-module--backButton--16LhC";
export var nextButton = "Amenities-module--nextButton--1xTXT";
export var cardsWrapper = "Amenities-module--cardsWrapper--2lncO";
export var card = "Amenities-module--card--azlO0";
export var image = "Amenities-module--image--1oAXP";
export var info = "Amenities-module--info--13Kut";
export var divider = "Amenities-module--divider--3Gc0l";
export var content = "Amenities-module--content--157Lq";
export var microLogo = "Amenities-module--microLogo--1IJXh";
/* Style Exports */
/* Some types can be rendered with different styles.
/* The key for the export is the page_selection value.
/* The value is the styles for that page_selection. */

// For doc.type === news_post
import * as arbolBlogPostStyles from '../templates/arbol/BlogPost.module.scss';
import * as homeBlogPostStyles from '../templates/home/BlogPost.module.scss';
export const blogPostStylesFor = {
  Arbol: arbolBlogPostStyles,
  Gomes: homeBlogPostStyles,
};

// For doc.type === in_the_news
import * as arbolNewsStyles from '@components/arbol/News/News.module.scss';
import * as homeNewsStyles from '@components/home/InTheNews/InTheNews.module.scss';
export const newsStylesFor = {
  Arbol: arbolNewsStyles,
  Gomes: homeNewsStyles,
};

/* Link Resolver */
/* Redirects the link from the prismic editor.
/* The return value and associated doc.type are also used by
/* the withPrismicUnpublishedPreview HOC on the 404 component
/* to determine the component that should render the document.
/* The doc.type => component map can be found in the prismic-preview-config. */
export const linkResolver = doc => {

  if (doc.type === 'unit') {
    return `/property/arbol/unit/${doc.data.apt_number}`;
  }
  if (doc.type === 'envy_unit') {
    return `/property/envy/unit/${doc.data.apt_number}`;
  }

  if (doc.type === 'neighborhood_place') {
    return `/property/arbol/neighborhood_place`;
  }
  if (doc.type === 'envy_neighborhood_place') {
    return `/property/envy/neighborhood_place`;
  }

  if (doc.type === 'news_post' && doc.data.page_selection === 'Arbol') {
    return `/property/arbol/news/${doc.data.title[0].text
      .trim()
      .replace(/\s/g, '-')
      .replace(/[^0-9a-z-]/gi, '')}`;
  }

  if (doc.type === 'news_post' && doc.data.page_selection === 'Gomes') {
    return `/news/${doc.data.title[0].text
      .trim()
      .replace(/\s/g, '-')
      .replace(/[^0-9a-z-]/gi, '')}`;
  }

  if (doc.type === 'in_the_news' && doc.data.page_selection === 'Arbol') {
    return `/property/arbol/in_the_news`;
  }

  if (doc.type === 'in_the_news' && doc.data.page_selection === 'Envy') {
    return `/property/envy/in_the_news`;
  }

  if (doc.type === 'in_the_news' && doc.data.page_selection === 'Gomes') {
    return `/in_the_news`;
  }

  if (doc.type === 'team_member') {
    return `/team/${doc.data.name.toLowerCase().replace(/\s/g, '-')}`;
  }

  if (doc.type === 'property') {
    return `/property`;
  }

  if (doc.type === 'homepage_slider') {
    return `/homepage_slider`;
  }

  if (doc.type === 'services') {
    return `/services`;
  }

  if (doc.type === 'homepage_about') {
    return `/preview/homepage`;
  }

  if (doc.type === 'team_page') {
    return `/preview/team`;
  }

  // Backup for all other types
  return '/unable_to_preview';
};

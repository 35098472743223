import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '@envy/Layout';
import Seo from '@components/seo';
import Amenities from '@envy/Amenities';
import TheNeighborhood from '@envy/TheNeighborhood';
import News from '@envy/News';
import ContactForm from '@envy/ContactForm';
import Footer from '@envy/Footer';
import { NextButton, BackButton } from '@envy/ArrowButtons';
import favicon from '@images/envy/favicon.png';
import ShareIcon from '@images/envy/UnitPage/share-icon.svg';
import LogoTiny from '@images/envy/logo-tiny.svg';
import * as styles from './unit.module.scss';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const isBrowser = typeof window !== 'undefined';

function UnitPage({ data }) {
  const unit = data.unit.data;

  if (!unit) {
    return null;
  }

  const imageCount = unit.banner_images ? unit.banner_images.length : 0;
  const [currentIndex, setCurrentIndex] = useState(0);
  const hasTour =
    unit.virtual_tour_url.url !== '' && unit.virtual_tour_url.url !== null;
  const shareUrl = isBrowser ? window.location.href : undefined;

  const handleNext = () => setCurrentIndex(currentIndex + 1);
  const handleBack = () => setCurrentIndex(currentIndex - 1);

  const calculateLeft = () => {
    if (!isBrowser) return;

    return window.innerWidth * currentIndex;
  };

  return (
    <Layout availability>
      <Seo
        title={`${unit.apt_type} Apartment | Envy by Gomes`}
        icon={favicon}
        description="Every apartment is uniquely outfitted, featuring natural hardwood flooring, quartz countertops, stainless steel appliances and lots of natural light."
      />
      <div className={styles.bannerCarousel}>
        {currentIndex < imageCount - 1 && (
          <NextButton
            onClick={handleNext}
            className={styles.nextButton}
            page={currentIndex}
            pageCount={imageCount}
          />
        )}
        {currentIndex > 0 && (
          <BackButton
            onClick={handleBack}
            className={styles.backButton}
            page={currentIndex}
            pageCount={imageCount}
          />
        )}
        <div
          className={styles.carousel}
          style={{ left: `-${calculateLeft()}px` }}
        >
          {unit.banner_images.map((image, index) => (
            <div
              key={index}
              className={styles.image}
              style={{ backgroundImage: `url(${image.image.url})` }}
            />
          ))}
        </div>
        {imageCount > 1 && (
          <div className={styles.mobileCounter}>
            <div className={styles.inner}>
              <span>{currentIndex + 1}</span>
              <span>{imageCount}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.fadedPatternBoxTopWrapper}><div className={styles.fadedPatternBoxTop} /></div>
      <div className={styles.container}>
        <div className={styles.sideLine}>
          <span>AMENITIES AT ENVY</span>
          <div className={styles.line} />
          <LogoTiny />
        </div>
        <div className={styles.unitDetails}>
          <div className={styles.detailsContainer}>
            <span>
              APT. {unit.apt_number} - AVAILABLE{' '}
              {unit.available_now ? 'NOW' : unit.available_at}
            </span>
            <h2>{unit.apt_type}</h2>
            <p>{unit.description.text}</p>
            <h3>ROOM AMENITIES</h3>
            <ul>
              <li>Modern, open concepts.</li>
              <li>Natural hardwood flooring.</li>
              <li>Quartz countertops and kitchen islands.</li>
              <li>Layouts designed to maximize the space.</li>
              <li>Ceiling-high windows offer natural light.</li>
              <li>Balconies.</li>
              <li>Beautiful city views.</li>
              <li>Window treatments.</li>
              <li>In-unit washer and dryer.</li>
            </ul>
          </div>
          <div className={styles.mediaContainer}>
            <img src={unit.apt_layout.url} alt="apartment layout" />
          </div>
        </div>
        <div className={styles.controls}>
          <div className={styles.detailsControls}>
            <div className={styles.priceContainer}>
              <span>STARTING AT</span>
              <div>
                <span>$</span>
                {unit.price.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                <span>
                  /MONTH<sup>*</sup>
                </span>
              </div>
            </div>
            <a
              id="button-apply"
              href={unit.application_url.url}
              className={styles.applyNow}
              target="_blank"
              rel="noreferrer"
            >
              APPLY NOW
            </a>
            <Tippy
              content={
                <div className={styles.shareContainer}>
                  <EmailShareButton url={shareUrl}>
                    <EmailIcon size={48} round />
                  </EmailShareButton>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={48} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={48} round />
                  </TwitterShareButton>
                </div>
              }
              interactive
              theme="light"
              trigger="click"
            >
              <div className={styles.sharing}>
                <ShareIcon />
                SHARE
              </div>
            </Tippy>
          </div>
          <div className={styles.mediaControls}>
            <div
              className={classNames(styles.toggle, {
                [styles.noTour]: !hasTour,
              })}
            >
              <div className={styles.active}>FLOOR PLAN</div>
              {hasTour && (
                <a
                  href={unit.virtual_tour_url.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  VIRTUAL TOUR
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.repeatContent}>
        <Amenities />
        <TheNeighborhood />
        <div className={classNames(styles.fadedPatternBox)} />
        <div className={classNames(styles.lined)} />
      </div>
      <div className={classNames(styles.dark, styles.bottomPattern)}>
        <News />
        <ContactForm />
        <div className={styles.repeatContent} style={{ padding: 0 }}>
          <Footer style={{ marginTop: '104px' }} />
        </div>
        <div className={classNames(styles.sidelines)} />
      </div>
    </Layout>
  );
}

UnitPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($apt_number: String!) {
    unit: prismicEnvyUnit(data: { apt_number: { eq: $apt_number } }) {
      data {
        apt_number
        apt_type
        available_at
        available_now
        description {
          text
        }
        floor
        price
        application_url {
          url
        }
        banner_images {
          image {
            url
          }
        }
        grid {
          url
        }
        apt_layout {
          url
        }
        virtual_tour_url {
          url
        }
      }
    }
  }
`;

export default withPrismicPreview(UnitPage);

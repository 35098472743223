import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import InTheNewsBase from '@components/InTheNews';
import * as styles from './News.module.scss';

function News({ style }) {
  const data = useStaticQuery(graphql`
    query ArbolInTheNews {
      allPrismicInTheNews(
        filter: {data: {page_selection: {eq: "Arbol"}}}
        sort: {order: DESC, fields: last_publication_date}
      ) {
        nodes {
          dataRaw
          data {
            quote
            logo {
              target
              url
            }
            selected_logo {
              url
            }
            link {
              url
            }
          }
        }
      }
    }
  `);

  const newsItemList = data.allPrismicInTheNews.nodes.map(e => ({ ...e.data, dataRaw: e.dataRaw }));

  return (
    <div className={styles.relativeWrapper} style={{ padding: 0, marginTop: '190px' }}>
      <StaticImage
        className={styles.decor}
        style={{ top: '100px', right: '50%', width: '1765px', height: '1791px' }}
        src="../../../images/arbol/landing-page/decor11.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '-120px', left: '-68%', width: '1784px', height: '2281px', zIndex: 0 }}
        src="../../../images/arbol/InTheNews/ink-splash.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ top: '-300px', left: '21%', zIndex: -1, mixBlendMode: 'multiply' }}
        src="../../../images/arbol/InTheNews/gray-shape.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <InTheNewsBase containerStyle={style} styles={styles} newsItemList={newsItemList} seeMoreLink="/property/arbol/news" />
    </div>
  );
}

News.propTypes = {
  style: PropTypes.object
};

News.defaultProps = {
  style: {}
};

export default News;

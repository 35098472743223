import React from 'react';
import * as styles from './Footer.module.scss';

import Background from '@images/home/footer/background.svg';
import Logo from '@images/home/new-logo.svg';
import Facebook from '@images/home/facebook.svg';
import Instagram from '@images/home/instagram.svg';
import AccessibilityIcon from '@images/home/footer/accessibility.svg';
import EqualityIcon from '@images/home/footer/equality.svg';

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.social}>
            <a href="https://www.facebook.com/gomesgroupllc" target="_blank" rel="noreferrer"><Facebook /></a>
            <a href="https://www.instagram.com/gomesgroup/" target="_blank" rel="noreferrer"><Instagram /></a>
          </div>
        </div>
        <div className={styles.center}>
          <Logo />
          <p>Copyright © {new Date().getFullYear()} GOMES GROUP. All right reserved.</p>
        </div>
        <div className={styles.right}>
          <div className={styles.contact}>
            <a href="tel:9737320125">973 732 0125</a>
            <a href="mailto:info@bygomes.com">info@bygomes.com</a>
          </div>
          <div className={styles.programs}>
            <EqualityIcon />
            <AccessibilityIcon />
          </div>
          <p>Copyright © {new Date().getFullYear()} GOMES GROUP.<br />All right reserved.</p>
        </div>
      </div>
      <Background className={styles.background} />
    </div>
  );
};

export default Footer;

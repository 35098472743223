import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as styles from './Banner.module.scss';
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
  const images = useStaticQuery(graphql`
    {
      prismicTeamPage {
        data {
          slider_images {
            image {
              url
            }
          }
        }
      }
    }
  `).prismicTeamPage.data.slider_images;
  const [position, setPosition] = useState(0);
  const handleChange = (index) => setPosition(index);

  return (
    <div className={styles.container}>
      <Carousel
        className={styles.carousel}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        infiniteLoop
        onChange={handleChange}
        autoPlay
      >
        {
          images.map((img, i) => (
            <img key={i} src={img.image.url} />
          ))
        }
      </Carousel>
      <div className={styles.overlay}>
        <p>MEET TEAM GOMES GROUP</p>
        <h1>AS DIVERSE AS THE CITY THEY LIVE IN.</h1>
        <div className={styles.position}>
          {(position + 1).toString().padStart(2, '0')}/
          <span>{images.length.toString().padStart(2, '0')}</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;

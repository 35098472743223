// extracted by mini-css-extract-plugin
export var relativeWrapper = "PhotoGallery-module--relativeWrapper--249RZ";
export var decor = "PhotoGallery-module--decor--V7h-9";
export var button = "PhotoGallery-module--button--36qOC";
export var lineAbove = "PhotoGallery-module--lineAbove--lQyZm";
export var left = "PhotoGallery-module--left--3lZug";
export var logo = "PhotoGallery-module--logo--2RRS6";
export var content = "PhotoGallery-module--content--3p751";
export var galleryContainer = "PhotoGallery-module--galleryContainer--1qkSF";
export var next = "PhotoGallery-module--next--qkfcP";
export var prev = "PhotoGallery-module--prev--3ddLz";
export var gallery = "PhotoGallery-module--gallery--fyuMX";
export var image = "PhotoGallery-module--image--2bW7C";
export var thumb = "PhotoGallery-module--thumb--LzyZS";
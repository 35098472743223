// extracted by mini-css-extract-plugin
export var relativeWrapper = "Footer-module--relativeWrapper--2WBvQ";
export var decor = "Footer-module--decor--3Vnyu";
export var button = "Footer-module--button--10AC1";
export var lineAbove = "Footer-module--lineAbove--35AM7";
export var footerWrapper = "Footer-module--footerWrapper--M2Pbd";
export var background = "Footer-module--background--2deqr";
export var container = "Footer-module--container--2j7Uc";
export var left = "Footer-module--left--RrkeZ";
export var social = "Footer-module--social--WpV4l";
export var center = "Footer-module--center--1PpN8";
export var right = "Footer-module--right--1I3WM";
export var contact = "Footer-module--contact--21FH3";
export var programs = "Footer-module--programs--C7hfe";
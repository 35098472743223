import React from 'react';
import FacebookIcon from '@images/envy/Footer/facebook.svg';
import InstagramIcon from '@images/envy/Footer/instagram.svg';
import Logo from '@images/envy/logo-block.svg';
import AccessibilityIcon from '@images/envy/Footer/accessibility.svg';
import EqualityIcon from '@images/envy/Footer/equality.svg';
import classNames from 'classnames';
import * as styles from './Footer.module.scss';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className={styles.container} style={{ marginTop: '104px' }}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <div className={styles.social}>
            <a href="https://www.facebook.com/envybygomes" target="_blank" rel="noreferrer"><FacebookIcon /></a>
            <a href="https://www.instagram.com/envybygomes" target="_blank" rel="noreferrer"><InstagramIcon /></a>
          </div>
        </div>
        <div className={classNames(styles.wrapper, styles.logo)}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contact}>
            <a href="tel:9733308859">973 330 8859</a>
            <a href="mailto:envy@bygomes.com">ENVY@BYGOMES.COM</a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.row, styles.lastRow)}>
        <div className={styles.wrapper} />
        <div className={styles.wrapper}>
          <p className={styles.copyright}>Copyright © {year} Envy by Gomes. All right reserved.</p>
        </div>
        <div className={classNames(styles.wrapper, styles.programs)}>
          <EqualityIcon />
          <AccessibilityIcon />
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState } from 'react';
import * as styles from './Properties.module.scss';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NextButton from '@images/home/banner/next.svg';
import PrevButton from '@images/home/banner/prev.svg';
import GLogo from '@images/home/glogo2.svg';
import classNames from 'classnames';
import { useRef } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { usePreviewDocument } from '@utils/usePreviewDocument';

const renderFull = (propertyList) => {
  const [currentIndex, setIndex] = useState(0);
  const carouselRef = useRef(null);
  const imageCarouselRef = useRef();
  const handleChange = (index) => setIndex(index);

  const handleArrowClick = (e) => {
    if (!carouselRef) return;

    const { direction } = e.target.dataset;

    if (direction === "next") {
      carouselRef.current.onClickNext();
      imageCarouselRef.current.onClickNext();
    } else {
      carouselRef.current.onClickPrev();
      imageCarouselRef.current.onClickPrev();
    }
  };

  return (
    <div className={styles.full}>
      <div className={styles.infoBox}>
        <div>
          <p>Our properties</p>
          {propertyList[currentIndex].logo}
          <Carousel
            ref={carouselRef}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            infiniteLoop
            onChange={handleChange}
            renderItem={(Obj) => (<div className={styles.item}>{Obj}</div>)}
          >
            {propertyList.map(property => property.info)}
          </Carousel>
        </div>
        <div className={styles.navigation}>
          <PrevButton
            className={classNames(styles.arrow, styles.prev)}
            onClick={handleArrowClick}
            data-direction="prev"
          />
          <div className={styles.position}>
            {(currentIndex + 1).toString().padStart(2, '0')}
            /
            <span>{propertyList.length.toString().padStart(2, '0')}</span>
          </div>
          <NextButton
            className={classNames(styles.arrow, styles.next)}
            onClick={handleArrowClick}
            data-direction="next"
          />
        </div>
        <GLogo className={styles.gLogo} />
      </div>
      <Carousel
        ref={imageCarouselRef}
        className={styles.imageCarousel}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        infiniteLoop
      >
        {propertyList.map(property => property.image)}
      </Carousel>
    </div>
  );
};

const renderSmall = (propertyList) => {
  return (
    <div className={styles.small}>
      <Carousel
        className={styles.cardCarousel}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeable
        emulateTouch
        autoPlay={false}
        interval={1e11}
        centerMode
        centerSlidePercentage={91}
      >
        {
          propertyList.map((property, i) => (
            <div key={property.info} className={styles.propertyCard}>
              <div className={styles.infoBlock}>
                <div className={styles.position}>
                  {(i + 1).toString().padStart(2, '0')}
                  /
                  <span>{propertyList.length.toString().padStart(2, '0')}</span>
                </div>
                <p>Our Properties</p>
                {property.logo}
                {property.info}
                <GLogo className={styles.gLogo} />
              </div>
              <div className={styles.image}>
                {property.image}
              </div>
            </div>
          ))
        }
      </Carousel>
    </div>
  );
};

const Properties = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProperty {
        nodes {
          data {
            position
            content
            headline
            logo {
              url
            }
            photo {
              thumbnails {
                carousel_size {
                  url
                }
              }
            }
            property_link {
              url
              target
            }
          }
        }
      }
    }
  `);

  const previewedProperty = usePreviewDocument('property');
  const properties = (previewedProperty ? [ previewedProperty ] : data.allPrismicProperty.nodes);
  properties.sort((a, b) => a.data.position - b.data.position);

  const propertyList = properties.map(property => property.data).map(property => ({
    info: (
      <div key={property.position} data-position={property.position} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h2>{property.headline}</h2>
        <p>{property.content}</p>
        {
          property.property_link.url &&
          <Link className={styles.button} to={property.property_link.url} target={property.property_link.target}>VIEW</Link>
        }
      </div>
    ),
    logo: <img src={property.logo.url} />,
    image: <img
      key={property.photo.thumbnails.carousel_size.url}
      src={property.photo.thumbnails.carousel_size.url}
    />,
    propertyUrl: property.property_link.url
  }));

  return (
    <section id="properties" className={styles.container}>
      {renderFull(propertyList)}
      {renderSmall(propertyList)}
    </section>
  );
};

export default Properties;

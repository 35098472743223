import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { globalHistory } from "@reach/router";
import axios from "axios";
import * as qs from "query-string";
import ErrorIcon from '@images/arbol/ContactForm/error-icon.svg';
import SuccessIcon from '@images/arbol/ContactForm/success-icon.svg';

const defaultFormData = {
  option: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: ''
};

const defaultAlert = {
  visible: false,
  type: '',
  message: ''
};

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

function ContactForm({
  heading, subHeading, options, handleOptionClick, styles, name, subject_template, id, onSubmit
}) {
  const selectValue = useSelector(state => state.contactFormOption);
  const [showDropdown, setShowDropdown] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [alert, setAlert] = useState({ ...defaultAlert });

  const handlePhoneChange = (e) => {
    let maskedValue = e.target.value.trim().replaceAll(/[^0-9|\s]/g, '');
    const isUnformattedNumber = /[0-9]{10}/.test(maskedValue);

    if (maskedValue.length === 4 || isUnformattedNumber) {
      maskedValue = insert(maskedValue, 3, ' ');
    }

    if (maskedValue.length === 8 || isUnformattedNumber) {
      maskedValue = insert(maskedValue, 7, ' ');
    }

    maskedValue = maskedValue.substring(0, 12);

    setFormData(prevFormData => ({
      ...prevFormData,
      phone: maskedValue
    }));
  };

  const handleChange = (e) => {
    e.persist();
    setFormData(prevFormData => ({ ...prevFormData, [e.target.name]: e.target.value }));
  };

  const validateField = (input) => {
    let isValid = true;
    const errorLabel = input.name === 'message'
      ? document.querySelector('form textarea + label')
      : document.querySelector(`form input[name="${input.name}"] + svg + svg + label`);

    if (!errorLabel) return isValid;

    if (input.validity.valid) {
      errorLabel.textContent = '';
      input.classList.add(styles.valid);
      input.classList.remove(styles.invalid);
    } else {
      isValid = false;
      input.setCustomValidity('');
      input.classList.remove(styles.valid);
      input.classList.add(styles.invalid);

      if (input.validity.valueMissing) {
        errorLabel.textContent = 'Required Field';
      } else if (input.validity.patternMismatch && input.name === 'phone') {
        errorLabel.textContent = 'Invalid phone number';
      } else if (input.validity.typeMismatch && input.name === 'email') {
        errorLabel.textContent = 'Invalid email address';
      } else {
        errorLabel.textContent = 'Invalid input';
      }
    }

    return isValid;
  };

  const startLiveValidation = () => {
    const inputFields = Array.from(document.getElementById(id).elements);

    inputFields.forEach(input => input.addEventListener('input', e => {
      validateField(e.target);
    }));
  };

  const validateForm = (e) => {
    let isValid = true;
    const inputFields = Array.from(e.target.elements);

    inputFields.forEach(input => {
      const isValidField = validateField(input);
      if (!isValidField) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLiveValidation();

    if (!validateForm(e)) return;

    onSubmit(formData);

    // Substitute the subject placeholders with values from the form.
    const subject = Object.keys(formData).reduce(
      (acc, key) => acc.replaceAll(`{${key}}`, formData[key]),
      subject_template
    );

    const axiosOptions = {
      url: globalHistory.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify({
        'form-name': name,
        subject,
        ...formData
      }),
    };

    axios(axiosOptions)
      .then(res => {
        const capitalize = e => e.split(' ').map(e => e.length > 1 ? `${e[0].toUpperCase()}${e.substring(1)}` : e).join(' ');

        if (window.dataLayer) {
          window.dataLayer.push({ event: capitalize(formData.option) });
        }

        console.log(res);
        setAlert({
          visible: true,
          type: 'success',
          className: 'alertSuccess',
          message: 'Thank you for your submission!'
        });
        setFormData({ ...defaultFormData, option: options[0] });
      })
      .catch(error => {
        console.log(error);
        setAlert({
          visible: true,
          type: 'error',
          className: 'alertError',
          message: 'An error occurred and your message could not be submitted'
        });
      }
      )
      .finally(res => {
        console.log(res);
      });
  };

  const handleDropdownClick = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      option: selectValue
    }));
  }, [selectValue]);

  return (
    <div className={styles.container} style={{ marginTop: '260px', zIndex: 2, position: 'relative' }} id="contact">
      <p>{heading}</p>
      <h2>{subHeading}</h2>
      <form
        id={id}
        name={name}
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        noValidate
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={name} />
        {
          subject_template &&
          <input type="hidden" name="subject" value={subject_template} />
        }
        <div className={classNames(styles.selectWrapper, { [styles.open]: showDropdown })}>
          <div
            className={styles.select}
            name="option"
            required
            onClick={handleDropdownClick}
          >
            {
              [formData.option, ...(options.filter(e => e !== formData.option))].map((option, index) => (
                (formData.option === option || showDropdown) &&
                <div
                  key={index}
                  className={styles.option}
                  onClick={e => handleOptionClick(e, setFormData)}
                  data-value={option}
                >
                  {option.toUpperCase()}
                </div>
              ))
            }
            <input
              name="option"
              value={formData.option}
              hidden="hidden"
              onChange={() => { }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <input
              type="text"
              placeholder="FIRST NAME"
              name="firstname"
              required
              value={formData.firstname}
              onChange={handleChange}
            />
            <ErrorIcon className={classNames(styles.icon, styles.error)} />
            <SuccessIcon className={classNames(styles.icon, styles.success)} />
            <label className={styles.errorLabel} />
          </div>
          <div className={styles.input}>
            <input
              type="text"
              placeholder="LAST NAME"
              name="lastname"
              required
              value={formData.lastname}
              onChange={handleChange}
            />
            <ErrorIcon className={classNames(styles.icon, styles.error)} />
            <SuccessIcon className={classNames(styles.icon, styles.success)} />
            <label className={styles.errorLabel} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <input
              type="email"
              placeholder="EMAIL"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <ErrorIcon className={classNames(styles.icon, styles.error)} />
            <SuccessIcon className={classNames(styles.icon, styles.success)} />
            <label className={styles.errorLabel} />
          </div>
          <div className={styles.input}>
            <input
              type="tel"
              placeholder="PHONE NUMBER"
              name="phone"
              pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
              value={formData.phone}
              minLength={12}
              maxLength={12}
              onChange={handlePhoneChange}
              required
            />
            <ErrorIcon className={classNames(styles.icon, styles.error)} />
            <SuccessIcon className={classNames(styles.icon, styles.success)} />
            <label className={styles.errorLabel} />
          </div>
        </div>
        <label htmlFor="message" className={styles.messageLabel}>Message</label>
        <div className={classNames(styles.row, styles.lg)}>
          <div className={styles.input}>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder=" "
              required
            ></textarea>
            <label className={styles.errorLabel} />
            <ErrorIcon className={classNames(styles.icon, styles.error)} />
            <SuccessIcon className={classNames(styles.icon, styles.success)} />
          </div>
        </div>

        {
          alert.visible &&
          <div className={classNames(styles.alert, styles[alert.className])}>{alert.message}</div>
        }

        <input type="submit" value="SUBMIT" className={styles.submit} />
      </form>
    </div>
  );
}

ContactForm.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  name: PropTypes.string,
  subject_template: PropTypes.string,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func
};

ContactForm.defaultProps = {
  name: "Contact Form",
  subject_template: undefined,
  onSubmit: () => { }
};

export default ContactForm;

// extracted by mini-css-extract-plugin
export var relativeWrapper = "BlogPost-module--relativeWrapper--3gHDB";
export var decor = "BlogPost-module--decor--10o-X";
export var container = "BlogPost-module--container--3MCs3";
export var backLinkContainer = "BlogPost-module--backLinkContainer--1_yFu";
export var backLink = "BlogPost-module--backLink--2HajZ";
export var newsHeader = "BlogPost-module--newsHeader--1xEwj";
export var newsFeatureMedia = "BlogPost-module--newsFeatureMedia--3KujX";
export var newsAuthorBlock = "BlogPost-module--newsAuthorBlock--uZaG0";
export var articleContents = "BlogPost-module--articleContents--2tDy2";
export var quote = "BlogPost-module--quote--191SO";
export var center = "BlogPost-module--center--20nPC";
export var right = "BlogPost-module--right--Un01b";
export var left = "BlogPost-module--left--3a3sL";
export var wide = "BlogPost-module--wide--1bT8-";
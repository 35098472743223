import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as styles from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import GLogo from '@images/home/glogo.svg';
import BigGLogo from '@images/home/big-g-logo.svg';
import MobileGLogo from '@images/home/mobile-glogo.svg';
import classNames from 'classnames';
import { withController, Parallax } from 'react-scroll-parallax';
import { useStaticQuery, graphql, Link } from "gatsby";
import favicon from "@images/home/favicon.ico";
import Quote from "@images/home/sample-quote.svg";

import Seo from '@components/seo';
import ParallaxCache from '@components/ParallaxCache';
import Layout from '@home/Layout';
import Banner from '@home/Banner';
import Properties from '@home/Properties';
import Services from '@home/Services';
import InTheNews from '@home/InTheNews';
import { usePreviewDocument } from '@utils/usePreviewDocument';

const TeamImage = ({ small, className, member }) => (
  <Link
    to={'/team/' + member?.name?.toLowerCase().replace(/\s/g, '-')}
    className={classNames(styles.teamImage, styles.fadeInZoom, className)}
  >
    <img src={small ? member?.photo.thumbnails.small.url : member?.photo.thumbnails.tall.url} />
    <div className={styles.name}>
      <p>{member?.name}</p>
    </div>
  </Link>
);

TeamImage.propTypes = {
  small: PropTypes.bool,
  member: PropTypes.object.isRequired,
  className: PropTypes.string
};

TeamImage.defaultProps = {
  className: '',
  small: false
};

const IndexPage = () => {
  const queryData = useStaticQuery(graphql`
    {
      prismicHomepageAbout {
        data {
          content
          headline1
          headline2
          images {
            image {
              url
            }
          }
        }
      }
      allPrismicTeamMember {
        nodes {
          data {
            name
            position
            photo {
              thumbnails {
                small {
                  url
                }
                tall {
                  url
                }
              }
            }
          }
        }
      }
      prismicTeamPage {
        data {
          enabled
        }
      }
    }
  `);
  const previewedAbout = usePreviewDocument('homepage_about')?.data;
  const aboutData = previewedAbout || queryData.prismicHomepageAbout.data;
  const memberList = queryData.allPrismicTeamMember.nodes.map(e => e.data);
  const teamPageEnabled = queryData.prismicTeamPage.data.enabled === true;

  const handleScroll = () => {
    const elementList = Array.from(
      document.getElementsByClassName(styles.fadeInUp)
    ).concat(Array.from(
      document.getElementsByClassName(styles.fadeInZoom)
    ));
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.4) {
          entry.target.classList.add(styles.isVisible);
        }
      });
    });

    elementList.forEach(el => observer.observe(el));
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout>
      <Seo
        title="Home"
        icon={favicon}
      />
      <ParallaxCache />
      <div className={styles.container}>
        <Banner />
        <Properties />
        <section id="about">
          <div className={styles.row}>
            <div className={classNames(styles.aboutQuote, styles.fadeInUp)}>
              <p>about gomes group</p>
              <p>{aboutData.headline1}</p>
            </div>
            {
              aboutData.images[0] &&
              <img
                className={classNames(styles.image, styles.fadeInZoom)}
                src={aboutData.images[0].image.url}
                alt="People chilling on a rooftop"
              />
            }
          </div>
          <div className={styles.row}>
            <div className={styles.secondImageContainer}>
              <div className={styles.overlay} />
              {
                aboutData.images[2] &&
                <img
                  className={classNames(styles.image, styles.second, styles.fadeInZoom)}
                  src={aboutData.images[2].image.url}
                  alt="bw picture of a street"
                />
              }
            </div>

            <div className={classNames(styles.aboutCard, styles.fadeInZoom)}>
              {
                aboutData.images[3] &&
                <img
                  className={styles.image}
                  src={aboutData.images[3].image.url}
                  alt="Group of people smiling"
                />
              }
              <div className={styles.text}>
                <GLogo />
                <h3 className={styles.fadeInUp}>A privately owned, multi-discipline collection of companies.</h3>
                <p className={styles.fadeInUp}>{aboutData.headline2}</p>
                <Link to="/team" className={styles.lineAbove}>
                  read more
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.bigLogo}>
            <Parallax y={[-10, 10]}>
              <BigGLogo />
              <MobileGLogo className={styles.mobile} />
            </Parallax>
          </div>
        </section>
        <section id="placeholderQuote">
          <Quote />
        </section>
        <Services />
        <InTheNews />
      </div>
      <section id="team">
        <div className={styles.bigLogo}>
          <Parallax y={[-20, 20]}>
            <BigGLogo />
            <MobileGLogo className={styles.mobile} />
          </Parallax>
        </div>
        {
          teamPageEnabled &&
          <div className={styles.content}>
            <div className={styles.heading}>
              <p>MEET OUR TEAM</p>
              <h2>
                With skills in multiple areas, our team is equipped to provide high-class service in the current real estate market.
              </h2>
            </div>
            <div className={styles.teamImages}>
              <div className={classNames(styles.row, styles.reverse)}>
                {
                  memberList[0] &&
                  <div className={styles.firstImage}>
                    <StaticImage className={styles.fadeInZoom} src="../images/home/team/bg-image1.jpg" alt="background image" />
                    <TeamImage
                      member={memberList[0]}
                    />
                    <div className={styles.decorBoxWrapper}>
                      <Parallax y={[-20, 20]}>
                        <div className={classNames(styles.decorBox, styles.fadeInZoom)} />
                      </Parallax>
                    </div>
                  </div>
                }
                {
                  memberList[1] &&
                  <TeamImage
                    member={memberList[1]}
                    small
                  />
                }
              </div>
              {
                memberList[2] &&
                <div className={styles.row}>
                  <TeamImage
                    className={styles.thirdImage}
                    member={memberList[2]}
                  />
                </div>
              }
              <div className={styles.row}>
                {
                  memberList[3] &&
                  <div className={styles.fourthImage}>
                    <TeamImage
                      member={memberList[3]}
                    />
                    <div className={classNames(styles.decorBoxWrapper, styles.fadeInZoom)}>
                      <Parallax y={[-20, 20]}>
                        <div className={styles.decorBox} />
                      </Parallax>
                    </div>
                  </div>
                }
                {
                  memberList[4] &&
                  <div className={styles.fifthImage}>
                    <StaticImage
                      className={classNames(styles.bgImage, styles.fadeInZoom)}
                      src="../images/home/team/bg-image2.jpg"
                      alt="background image"
                    />
                    <TeamImage
                      member={memberList[4]}
                    />
                    <div className={classNames(styles.decorBoxWrapper, styles.fadeInZoom)}>
                      <div className={styles.decorBox} />
                    </div>
                  </div>
                }
              </div>
              <div className={classNames(styles.row, styles.reverse)}>
                {
                  memberList[5] &&
                  <TeamImage
                    className={styles.sixthImage}
                    member={memberList[5]}
                  />
                }
                {
                  memberList[6] &&
                  <TeamImage
                    className={styles.seventhImage}
                    member={memberList[6]}
                    small
                  />
                }
              </div>
              <div className={styles.row}>
                {
                  memberList[7] &&
                  <div className={styles.eighthImage}>
                    <TeamImage
                      member={memberList[7 ]}
                    />
                    <div className={styles.decorBoxWrapper}>
                      <Parallax y={[-20, 20]}>
                        <div className={classNames(styles.decorBox, styles.fadeInZoom)} />
                      </Parallax>
                    </div>
                  </div>
                }
              </div>
              <div className={styles.row}>
                <a href="/team" className={styles.button}>MEET THE TEAM</a>
              </div>
            </div>
          </div>
        }
      </section>
    </Layout>
  );
};

export default withController(IndexPage);

import React from 'react';
import * as styles from './TeamMember.module.scss';
import PropTypes from 'prop-types';
import favicon from "@images/home/favicon.ico";
import { RichText } from 'prismic-reactjs';

import Seo from '@components/seo';
import Layout from '@home/Layout';
import { graphql } from 'gatsby';

const TeamMember = ({ data }) => {
  const member = data.prismicTeamMember.data;

  return (
    <Layout>
      <Seo title="Team" icon={favicon} />
      <div className={styles.container}>
        <div className={styles.heading}>
          <div className={styles.details}>
            <span className={styles.title}>{member.title}</span>
            <h1>{member.name}</h1>
            <p>{member.intro}</p>
          </div>
          <div>
            <img src={member.photo.thumbnails.large.url} className={styles.image} />
          </div>
        </div>
        <div className={styles.content}>
          <h2>{member.headline}</h2>
          <RichText render={member.content.raw} />
        </div>
      </div>
    </Layout>
  );
};

TeamMember.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
query($name: String!) {
  prismicTeamMember(data: { name: { eq: $name } }) {
    data {
      title
      name
      position
      photo {
        thumbnails {
          large {
            url
          }
        }
      }
      intro
      headline
      content {
        raw
      }
    }
  }
}
`;

export default TeamMember;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'gatsby';
import LetsTalkButtonMobile from "@images/arbol/lets-talk-mobile.svg";
import LogoTinyA from "@images/arbol/landing-page/logo-tiny-a.svg";
import * as styles from "./LetsTalk.module.scss";
import classNames from "classnames";

function LetsTalk() {
  const [sticky, setSticky] = useState(0);

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    document.body.scrollTop || document.documentElement.scrollTop;

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    const isSmall = document.documentElement.clientWidth < 1024;
    const scrollPercent = isSmall ? 0.945 : 0.97;

    if (scrolled > scrollPercent) {
      setSticky(1);
    } else {
      setSticky(0);
    }
  };
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({ type: "SET_CONTACT_TALK" });
    window.location = "#contact";
  };

  return (
    <div
      className={classNames(styles.container, { [styles.stickyContainer]: sticky })}
      onClick={handleClick}
    >
      <Link to="#contact">
        <LogoTinyA className={styles.logoA} />
        <LetsTalkButtonMobile className={styles.mobile} />
        <span className={styles.decor}></span>
        LET’S TALK
      </Link>
    </div>
  );
}

export default LetsTalk;

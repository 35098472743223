// extracted by mini-css-extract-plugin
export var relativeWrapper = "team-module--relativeWrapper--2wNw_";
export var decor = "team-module--decor--11Klv";
export var button = "team-module--button--10fhY";
export var lineAbove = "team-module--lineAbove--jhsmx";
export var container = "team-module--container--3TJAC";
export var mobile = "team-module--mobile--3zK8-";
export var content = "team-module--content--bc2dK";
export var teamList = "team-module--teamList--1BcJV";
export var expanded = "team-module--expanded--rAenJ";
export var teamItem = "team-module--teamItem--3Y-BW";
export var image = "team-module--image--3XwzD";
export var name = "team-module--name--2CGxs";
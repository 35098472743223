import React from "react";
import PropTypes from "prop-types";
import { RichText } from 'prismic-reactjs';
import classNames from "classnames";
import { Link } from 'gatsby';
import { blogPostStylesFor as stylesFor } from '@utils/previewResolver';

function renderSlices(slices, styles) {
  return slices.map((slice, index) => {
    switch (slice.slice_type) {
      case 'rich_text':
        return slice.items.map((item, i) => (
          <RichText key={`${slice.id}:${i}`} render={item.text.raw} />
        ));
      case 'image':
        return (
          <img
            key={index}
            src={slice.primary.image.url}
            className={classNames({
              [styles.right]: slice.slice_label === 'right',
              [styles.left]: slice.slice_label === 'left',
              [styles.wide]: slice.slice_label === 'full_width',
              [styles.center]: slice.slice_label === null
            })}
          />
        );
      case 'quote':
        return (
          <p className={styles.quote} key={index}>
            {slice.primary.quote.text}
          </p>
        );
      default:
        return null;
    }
  });
}

function BlogPost({ page, styles: liveStyles }) {

  if (!page) return null;

  const styles = liveStyles || stylesFor[page.page_selection]; // fallback for previews

  return (
    <div className={styles.container}>
      <div className={styles.newsHeader}>
        <div className={styles.newsFeatureMedia}>
          <img src={page.main_image.url} />
        </div>
        <h2>{page.tags}</h2>
        <h1>{page.title.text}</h1>
        <div className={styles.newsAuthorBlock}>
          {
            page.author_image.url &&
            <img src={page.author_image.url} />
          }
          <p>{page.author.text}</p>
        </div>
      </div>

      <div className={styles.articleContents}>
        {renderSlices(page.body, styles)}
      </div>
      <div className={styles.backLinkContainer}>
        <Link className={styles.backLink} to="/property/arbol/news">BACK TO NEWS</Link>
      </div>
    </div>
  );
}

BlogPost.propTypes = {
  page: PropTypes.object.isRequired,
  styles: PropTypes.object
};

BlogPost.defaultProps = {
  styles: null
};

export default BlogPost;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { BackButton, NextButton } from '@arbol/ArrowButtons';
import * as styles from './Amenities.module.scss';

const tabList = ['BIERGARTEN', 'COWORKING', 'CAFÉ', 'LOUNGE + ARCADE', 'TERRACES', 'GYM'];

const carouselContent = [
  {
    title: 'Biergarten Coming 2023',
    content: (
      <p>
        Integrated into our community are places to gather. Only one block away is a lovely beer garden available to all our renters and guests featuring local and international brews. It’s a place we know you’ll make a regular gathering spot.
      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/biergarten.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
  {
    title: 'Coworking',
    content: (
      <p>
        Work from home is here to stay. Coworking spaces allow you to create a productive work/life balance and stay inspired in a clean and modern environment.
      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/coworking.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
  {
    title: 'Café',
    content: (
      <p>
        Our cafe is open to renters and their guests for a quick bite to eat, a leisurely lunch or afternoon coffee while working or connecting with friends.
      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/cafe.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
  {
    title: 'Lounge + Arcade',
    content: (
      <p>
        Most luxury apartments have a lounge, but not many have a dual-function arcade. Unwind after the workday with some friendly competition with your fellow tenants or spend some solitary time reading in the lounge.
      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/lounge.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
  {
    title: 'Terraces',
    content: (
      <p>
        Zen Garden<br />
        Our garden is a peaceful respite at ground level for a more secluded place to relax and read a book.
        <br />
        <br />
        Elevated Courtyard<br />
        Outdoor living is just as important as indoor. Overlooking the Zen Garden, our courtyard is open to all renters. Enjoy a drink with friends or entertain guests in this laid back setting.

      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/terrace.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
  {
    title: 'Gym',
    content: (
      <p>
Arbol offers state of the art gym equipment including the brand new MIRROR Technology gym by Lululemon. This technology is provided to our renters with no additional cost. We also host fitness events throughout the year, including yoga, kickboxing and more.      </p>
    ),
    image: (
      <StaticImage
        src="../../../images/arbol/Amenities/gym.jpeg"
        className={styles.image}
        alt="people on a terrace"
      />
    )
  },
];

function Amenities() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cardLeftPos, setCardLeftPos] = useState(0);

  const calculateLeft = (index = selectedTab) => {
    if (index === 0) {
      return setCardLeftPos(window.innerWidth < 1066 ? 35 : 113);
    }
    const width = window.innerWidth < 1214 ? window.innerWidth : window.innerWidth;
    const card = document.getElementsByClassName(styles.card)[0];
    const cardWidth = card.scrollWidth;
    const left = ((width - cardWidth) / 2) - ((cardWidth + 45) * index);

    setCardLeftPos(left);
  };

  const handleSelection = (e) => {
    const index = parseInt(e.currentTarget.dataset.index);

    setSelectedTab(index);
    calculateLeft(index);
  };

  const goToTab = (tabIndex) => {
    const newIndex = tabIndex < 0 ? tabList.length - 1 : tabIndex % tabList.length;

    setSelectedTab(newIndex);
    calculateLeft(newIndex);
  };

  useEffect(() => {
    calculateLeft();
  }, []);

  return (
    <div className={styles.relativeWrapper} style={{ padding: 0 }}>
      <div className={styles.container} id="amenities">
        <div className={styles.textContainer}>
          <span>AMENITIES AT ARBOL</span>
          <h2>The adventure is right here.</h2>
          <h2>And there. And everywhere.</h2>
          <p>
            How you spend your time influences who you are and we provide a living experience that supports
            you in all your endeavors. Surrounded by modern amenities and gorgeous design, you are free to
            focus on what matters most to you. Arbol is equipped with a range of dynamic spaces, helping you
            have an easier, healthier and more enjoyable lifestyle. It is all waiting for you to discover.
          </p>
        </div>

        <div className={styles.carouselContainer}>
          <div className={styles.tabs}>
            {
              tabList.map((tabTitle, index) => (
                <div
                  key={index}
                  onClick={handleSelection}
                  className={classNames(styles.tab, { [styles.selected]: index === selectedTab })}
                  data-index={index}
                >
                  <div className={styles.backgroundImage} />
                  <div className={styles.foregroundImage} />
                  <span>{tabTitle}</span>
                </div>
              ))
            }
          </div>

          <div className={styles.mobileTabs}>
            <p>AMENITIES</p>
            <div className={styles.decorLine} />
          </div>

          <div className={styles.carousel}>
            <NextButton
              page={selectedTab}
              pageCount={tabList.length}
              className={classNames(styles.arrow, styles.nextButton)}
              onClick={() => goToTab(selectedTab + 1)}
              small
            />
            <BackButton
              page={selectedTab}
              pageCount={tabList.length}
              className={classNames(styles.arrow, styles.backButton)}
              onClick={() => goToTab(selectedTab - 1)}
              small
            />
            <div className={styles.cardsWrapper} style={{ left: `${cardLeftPos}px` }}>
              {
                carouselContent.map((data, index) => (
                  <div key={index} className={styles.card}>
                    {data.image}
                    <div className={styles.info}>
                      <span>{data.title}</span>
                      <div className={styles.divider} />
                      {data.content}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', right: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor8.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
      <StaticImage
        className={styles.decor}
        style={{ bottom: '-800px', left: '64%', width: '1126px' }}
        src="../../../images/arbol/landing-page/decor9.png"
        placeholder="None"
        loading="eager"
        alt="decor"
      />
    </div>
  );
}

export default Amenities;

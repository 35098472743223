import classNames from 'classnames';
import React from 'react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as styles from './Services.module.scss';
import { useStaticQuery, graphql } from "gatsby";
import { usePreviewDocument } from '@utils/usePreviewDocument';
import { useEffect } from 'react';

const serviceOrder = {
  development: 0,
  construction: 1,
  management: 2,
  consulting: 3,
  investments: 4
};

const Services = () => {
  const previewedService = usePreviewDocument('services');
  const serviceCarousel = useRef(null);
  const imageRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = previewedService ? () => {} : useDispatch();
  const data = useStaticQuery(graphql`
    {
      allPrismicServices {
        nodes {
          data {
            content
            headline
            photo {
              url
            }
            service_name
          }
        }
      }
    }
  `);
  const handleServiceClick = (e) => {
    const { target } = e;

    serviceCarousel.current.handleClickThumb(parseInt(target.dataset.index));
    setSelectedIndex(parseInt(target.dataset.index));
  };
  const handleContactClick = (e) => {
    const { target }  = e;
    dispatch({ type: 'SET_CONTACT_TYPE', contactTypeName: target.dataset.name });
  };

  const services = previewedService ? [ previewedService ] : data.allPrismicServices.nodes;

  const serviceList = services.map(e => e.data).map(e => ({
    name: e.service_name.toLowerCase(),
    content: (
      <>
        <h3>{e.headline}</h3>
        <p>{e.content}</p>
      </>
    ),
    image: <img className={styles.image} src={e.photo.url} />
  })).sort((a, b) => (serviceOrder[a.name] - serviceOrder[b.name]));

  const updateServiceListHeight = () => {
    if (imageRef.current) {
      const height = imageRef.current.clientHeight;

      Array.from(document.getElementsByClassName(styles.serviceList)).forEach((e) => {
        e.style.height = `${height}px`;
      });
    }
  };

  useEffect(() => {
    updateServiceListHeight();
    window.addEventListener('resize', updateServiceListHeight);
  }, []);

  return (
    <section className={styles.services} id="services">
      <div className={styles.textBlock}>
        <p>our services</p>
        <h2>What We Offer.</h2>
        <p>
          As a leader in the real estate business, we offer an array of services to better serve our clients and to fully immerse ourselves in the facets of our community.
        </p>
      </div>
      <div className={styles.greenBlock}>
        <div className={styles.serviceList}>
          {
            serviceList.map((service, i) => (
              <div
                key={service.name}
                onClick={handleServiceClick}
                data-index={i}
                className={selectedIndex === i ? styles.active : undefined}
              >
                {service.name}
              </div>
            ))
          }
        </div>
        <Carousel
          ref={serviceCarousel}
          className={styles.serviceCarousel}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          infiniteLoop
        >
          {
            serviceList.map(service => (
              <div key={service.name} className={styles.item}>
                <div className={styles.serviceDetails}>
                  {service.content}
                  <a
                    className={styles.lineAbove}
                    href="#contact"
                    onClick={handleContactClick}
                    data-name={service.name}
                  >
                    contact us
                  </a>
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.block} />
                  <div className={styles.imageContainer} ref={imageRef}>
                    {service.image}
                  </div>
                </div>
              </div>
            ))
          }
        </Carousel>
        <Carousel
          className={classNames(styles.serviceCarousel, styles.mobile)}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          centerMode
          centerSlidePercentage={85}
          swipeable
          emulateTouch
          autoPlay={false}
          interval={1e11}
        >
          {
            serviceList.map(service => (
              <div key={service.name} className={styles.item}>
                <div className={styles.serviceDetails}>
                  {service.content}
                  <a
                    className={styles.lineAbove}
                    href="#contact"
                    onClick={handleContactClick}
                    data-name={service.name}
                  >
                    contact us
                  </a>
                </div>
                <div className={styles.imageContainer}>
                  {service.image}
                </div>
              </div>
            ))
          }
        </Carousel>
      </div>
    </section>
  );
};

export default Services;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './ArrowButton.module.scss';

function ArrowButton({ page, pageCount, small }) {
  return (
    <div className={classNames(styles.content, { [styles.small]: small })}>
      <div className={styles.position}>
        <span>{page + 1}</span>
        <span>{pageCount}</span>
      </div>
      <div className={styles.arrow} />
    </div>
  );
}

ArrowButton.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  small: PropTypes.bool
};

ArrowButton.defaultProps = {
  small: false
};

export default ArrowButton;

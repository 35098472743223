// extracted by mini-css-extract-plugin
export var button = "index-module--button--2YDWh";
export var lineAbove = "index-module--lineAbove--7o4Ro";
export var relativeWrapper = "index-module--relativeWrapper--3Fj9_";
export var decor = "index-module--decor--oPl_1";
export var fadeInUp = "index-module--fadeInUp--VdOxt";
export var isVisible = "index-module--isVisible--1NMLD";
export var fadeInZoom = "index-module--fadeInZoom--1Qg07";
export var container = "index-module--container--k7ALA";
export var row = "index-module--row--3x0jc";
export var bigLogo = "index-module--bigLogo--cBi2r";
export var mobile = "index-module--mobile--2E52c";
export var aboutQuote = "index-module--aboutQuote--3vUN-";
export var image = "index-module--image--2THIp";
export var secondImageContainer = "index-module--secondImageContainer--ZO4Dt";
export var bgImage = "index-module--bgImage--1w8_O";
export var second = "index-module--second--2R84c";
export var overlay = "index-module--overlay--25Ach";
export var aboutCard = "index-module--aboutCard--2QJmU";
export var text = "index-module--text--cLZGE";
export var content = "index-module--content--ZXwgT";
export var heading = "index-module--heading--2qVhF";
export var teamImages = "index-module--teamImages--Yrz6B";
export var reverse = "index-module--reverse--30Tiy";
export var decorBoxWrapper = "index-module--decorBoxWrapper--30dXV";
export var firstImage = "index-module--firstImage--JjCQO";
export var decorBox = "index-module--decorBox--3FP15";
export var fourthImage = "index-module--fourthImage--2p0rQ";
export var fifthImage = "index-module--fifthImage--2bkx9";
export var name = "index-module--name--1V8Qj";
export var eighthImage = "index-module--eighthImage--2ndV2";
export var teamImage = "index-module--teamImage--2F46A";
export var thirdImage = "index-module--thirdImage--1yyYI";
export var sixthImage = "index-module--sixthImage--b-QsZ";
export var seventhImage = "index-module--seventhImage--16MRX";
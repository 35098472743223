// extracted by mini-css-extract-plugin
export var relativeWrapper = "TeamMember-module--relativeWrapper--2RgiZ";
export var decor = "TeamMember-module--decor--2ZPu1";
export var button = "TeamMember-module--button--1CaHv";
export var lineAbove = "TeamMember-module--lineAbove--3I6KH";
export var container = "TeamMember-module--container--37W6c";
export var heading = "TeamMember-module--heading--1218n";
export var details = "TeamMember-module--details--KXjqb";
export var title = "TeamMember-module--title--3DLU9";
export var image = "TeamMember-module--image--3j_M6";
export var content = "TeamMember-module--content--3LP0A";
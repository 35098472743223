// extracted by mini-css-extract-plugin
export var relativeWrapper = "Services-module--relativeWrapper--eHmE4";
export var decor = "Services-module--decor--7a2HW";
export var button = "Services-module--button--32_pO";
export var lineAbove = "Services-module--lineAbove--1PuBs";
export var services = "Services-module--services--1QiT_";
export var textBlock = "Services-module--textBlock--1oy67";
export var greenBlock = "Services-module--greenBlock--2SJx4";
export var serviceList = "Services-module--serviceList--8oSOh";
export var active = "Services-module--active--2TCz7";
export var serviceCarousel = "Services-module--serviceCarousel--MmuU3";
export var mobile = "Services-module--mobile--Lc0yw";
export var item = "Services-module--item--1w5tu";
export var serviceDetails = "Services-module--serviceDetails--1kApK";
export var wrapper = "Services-module--wrapper--LgaKt";
export var block = "Services-module--block--3Nmjj";
export var imageContainer = "Services-module--imageContainer--2SnCg";
export var image = "Services-module--image--2PX1b";
import React from 'react';
import Header from '@components/Header';
import FullLogo from '@images/home/full-logo.svg';
import MobileLogo from '@images/home/mobile-logo.svg';
import Facebook from '@images/home/facebook.svg';
import Instagram from '@images/home/instagram.svg';
import * as styles from './Header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import GLogo from '@images/home/big-g-logo.svg';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { dropdownTypes } from '../ContactForm/ContactForm';

const isBrowser = typeof window !== 'undefined';

const HomeHeader = props => {
  const dispatch = useDispatch();
  const path = isBrowser ? window.location.pathname : '/';
  const isRootPage = path === '/';
  const data = useStaticQuery(graphql`
    query {
      prismicTeamPage {
        data {
          enabled
        }
      }
      prismicGomesHomepageGlobal {
        id
        data {
          work_here_link {
            target
            url
          }
        }
      }
    }
  `);

  const handleWorkHere = (handleClose) => {
    handleClose();

    dispatch({ type: 'SET_CONTACT_TYPE', contactType: dropdownTypes.EMPLOYMENT });
  };

  return <Header
    {...props}
    styles={styles}
    headerContent={() => (
      <>
        <div className={styles.logoContainer}>
          <a href="/" style={{ color: "#1a1818"}}>
            <FullLogo />
            <MobileLogo className={styles.mobile} />
          </a>
        </div>
        <div className={styles.rightContainer}>
          <a
            href="https://www.rentcafe.com/residentservices/apartmentsforrent/userlogin.aspx"
            target="_blank"
            className={styles.residentLink}
            rel="noreferrer"
          >
            RESIDENTS
          </a>
          <div className={styles.socials}>
            <a href="https://www.facebook.com/gomesgroupllc" target="_blank" rel="noreferrer"><Facebook /></a>
            <a href="https://www.instagram.com/gomesgroup" target="_blank" rel="noreferrer"><Instagram /></a>
          </div>
        </div>
      </>
    )}
    expandedContent={(handleClose) => (
      <>
        <div className={styles.backgroundRectangle}>
          <GLogo className={styles.logo} />
        </div>
        <div className={styles.menuList}>
          <ul>
            <li>
              <Link
                onClick={handleClose}
                className="link animate__animated"
                to={isRootPage ? '#properties' : '/#properties'}
              >
                Properties
              </Link>
            </li>
            <li>
              <Link
                onClick={handleClose}
                className="link animate__animated"
                to={isRootPage ? '#services' : '/#services'}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                onClick={handleClose}
                className="link animate__animated"
                to={isRootPage ? '#news' : '/#news'}
              >
                News
              </Link>
            </li>
            <li>
              <a
                onClick={handleClose}
                className="link animate__animated"
                href="/team"
              >
                About
              </a>
            </li>
            {
              data.prismicGomesHomepageGlobal.data.work_here_link.url !== null &&
              <li>
                <a
                  onClick={() => handleWorkHere(handleClose)}
                  className="link animate__animated"
                  href={data.prismicGomesHomepageGlobal.data.work_here_link.url}
                  target={data.prismicGomesHomepageGlobal.data.work_here_link.target}
                >
                  Work here
                </a>
              </li>
            }
            <li>
              <a
                onClick={handleClose}
                className="link animate__animated"
                href="#contact"
              >
                Contact us
              </a>
            </li>
          </ul>
          <a
            href="https://www.rentcafe.com/residentservices/apartmentsforrent/userlogin.aspx"
            target="_blank"
            className={styles.residentLink}
            rel="noreferrer"
          >
            RESIDENTS
          </a>
        </div>
        <div className={styles.imageContainer}>
          <StaticImage
            className={styles.image}
            src="../../../images/home/menu.jpg"
            alt="Group eating on rooftop"
          />
        </div>
      </>
    )}
  />;
};

export default HomeHeader;

import React from 'react';
import PropTypes from 'prop-types';
import BlogPostBase from '@components/BlogPost';
import * as styles from './BlogPost.module.scss';

import Layout from '@home/Layout';
import { graphql } from 'gatsby';

const BlogPost = ({ data }) => {
  const page = {
    ...data.prismicNewsPost.data,
    id: data.prismicNewsPost.id,
    tags: data.prismicNewsPost.tags
  };

  return (
    <Layout transparentHeader={false}>
      <BlogPostBase styles={styles} page={page} />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
query($id: String!) {
  prismicNewsPost(id: { eq: $id }) {
    tags
    id
    data {
      featured
      title {
        text
      }
      intro_text {
        text
      }
      author {
        text
        html
      }
      meta_description {
        text
      }
      author_image {
        url
      }
      main_image {
        url
      }
      small_image {
        url
      }
      body {
        ... on PrismicNewsPostDataBodyRichText {
          id
          slice_type
          items {
            text {
              raw
            }
          }
        }
        ... on PrismicNewsPostDataBodyImage {
          id
          slice_type
          slice_label
          primary {
            image {
              url
            }
          }
        }
        ... on PrismicNewsPostDataBodyQuote {
          id
          slice_type
          primary {
            quote {
              text
            }
          }
        }
      }
    }
  }
}`;

export default BlogPost;

import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { createStore } from "redux";
import { Provider } from "react-redux";
import Header from "@arbol/Header";
import LetsTalk from "@arbol/LetsTalk";
import ContactForm from "@arbol/ContactForm";
import "@components/layout.css";

const { optionTypes } = ContactForm;
const initialState = {
  contactFormOption: optionTypes.TALK,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTACT_TALK":
      return {
        ...state,
        contactFormOption: optionTypes.TALK,
      };
    case "SET_CONTACT_TOUR":
      return {
        ...state,
        contactFormOption: optionTypes.TOUR,
      };
    default:
      return state;
  }
};

const Layout = ({ transparentHeader, children }) => {
  const store = createStore(reducer);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Provider store={store}>
      <Header
        siteTitle={data.site.siteMetadata?.title || "Arbol"}
        transparent={transparentHeader}
      />

      <main style={{ overflowX: "hidden" }}>
        <div style={{ position: "relative" }}>
          {children}
          <LetsTalk />
        </div>
      </main>
    </Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transparentHeader: PropTypes.bool,
};

Layout.defaultProps = {
  transparentHeader: true,
};

export default Layout;

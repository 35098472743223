import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import "animate.css";
import LogoSmall from "@images/arbol/logo-small.svg";
import LogoTiny from "@images/arbol/logo-tiny.svg";
import PhoneIcon from "@images/arbol/header/phone-icon.svg";
import MailIcon from "@images/arbol/header/mail-icon.svg";
import GomesLogo from "@images/home/full-logo.svg";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import BaseHeader from '@components/Header';
import * as styles from "./styles.module.scss";


const getHref = (anchor) => {
  const path = typeof window === 'undefined' ? '' : location.pathname;

  if (path === '/property/arbol') return anchor;

  if (anchor === '#contact') return anchor;

  const unitComponents = ['#news', '#amenities', '#neighborhood'];
  if (/\/property\/arbol\/[0-9]+\/?$/.test(path) && unitComponents.includes(anchor)) return anchor;

  return `/property/arbol/${anchor}`;
};

const Header = props => {
  return (
    <BaseHeader
      styles={styles}
      transparent={props.transparent}
      headerContent={(header) => (
        <>
          <div className={styles.leftContainer}>
            <Link to="#units" id="button-availability" className={styles.button}>
              AVAILABILITY
            </Link>
          </div>
          <Link to="/property/arbol">
            {props.transparent == true && !['mini', 'hidden'].includes(header.state) ? (
              <LogoSmall className={styles.logoSmall} />
            ) : (
              <LogoTiny className={styles.logoTiny} />
            )}
          </Link>
          <div className={classNames(styles.contactContainer, styles.lg)}>
            <a href="tel:9735543475">973 554 3475</a>
            <a href="mailto:arbol@bygomes.com">ARBOL@BYGOMES.COM</a>
          </div>
          <div className={classNames(styles.contactContainer, styles.sm)}>
            <div className={styles.iconContainer}>
              <a href="tel:9735543475">
                <PhoneIcon />
              </a>
              <a href="mailto:arbol@bygomes.com">
                <MailIcon />
              </a>
            </div>
          </div>
        </>
      )}
      expandedContent={(handleClose) => (
        <>
          <div className={styles.menuList}>
            <a
              href={getHref('#units')}
              onClick={handleClose}
              className={classNames(styles.button, styles.mobile)}
            >
              AVAILABILITY
            </a>
            <ul>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#units')}
                >
                  Units
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#amenities')}
                >
                  Amenities
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#neighborhood')}
                >
                  Neighborhood
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#news')}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleClose}
                  className="link animate__animated"
                  to={getHref('#contact')}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <a
                  onClick={handleClose}
                  className="link animate__animated"
                  href="https://sitemanager.rentcafe.com/residentservices/arbol-by-gomes/userlogin.aspx"
                >
                  Residents
                </a>
              </li>
            </ul>
            <Link to="/" className={styles.logoLink}>
              <GomesLogo className={styles.gomesLogo} />
            </Link>
          </div>
          <div className={styles.imageContainer}>
            <StaticImage
              className={styles.image}
              src="../../../images/arbol/header/menu.jpg"
              alt="Group eating on rooftop"
            />
            <StaticImage
              className={styles.decor}
              src="../../../images/arbol/header/decor.png"
              alt="Group eating on rooftop"
            />
          </div>
        </>
      )}
    />
  );
};

Header.propTypes = {
  transparent: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  transparent: true,
};

export default Header;

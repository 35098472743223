import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import InTheNewsBase from '@components/InTheNews';
import * as styles from './News.module.scss';

function News({ style }) {
   const data = useStaticQuery(graphql`
     query EnvyInTheNews {
       allPrismicInTheNews(
         filter: {data: {page_selection: {eq: "Envy"}}}
         sort: {order: DESC, fields: last_publication_date}
       ) {
         nodes {
           dataRaw
           data {
             quote
             logo {
               target
               url
             }
             selected_logo {
               url
             }
             link {
               url
             }
           }
         }
       }
     }
  `);

  const newsItemList = data.allPrismicInTheNews.nodes.map(e => ({ ...e.data, dataRaw: e.dataRaw }));

  return (
    <div className={styles.relativeWrapper} style={{ padding: 0, marginTop: '190px' }}>
      <InTheNewsBase containerStyle={style} styles={styles} newsItemList={newsItemList} seeMoreLink="/property/envy/news" />
    </div>
  );
}

News.propTypes = {
  style: PropTypes.object
};

News.defaultProps = {
  style: {}
};

export default News;

// extracted by mini-css-extract-plugin
export var relativeWrapper = "BlogPost-module--relativeWrapper--1bv1d";
export var decor = "BlogPost-module--decor--9eQTS";
export var hamburger = "BlogPost-module--hamburger--1_iQY";
export var units = "BlogPost-module--units--2JczI";
export var reactCalendarMonthViewDays = "BlogPost-module--react-calendar__month-view__days--3JN5A";
export var reactCalendarTile = "BlogPost-module--react-calendar__tile--2NO0a";
export var reactCalendarTileActive = "BlogPost-module--react-calendar__tile--active--2Fd-w";
export var container = "BlogPost-module--container--lVGcN";
export var backLinkContainer = "BlogPost-module--backLinkContainer--26yIu";
export var backLink = "BlogPost-module--backLink--21_d0";
export var newsHeader = "BlogPost-module--newsHeader--1asc9";
export var newsFeatureMedia = "BlogPost-module--newsFeatureMedia--2uHHQ";
export var newsAuthorBlock = "BlogPost-module--newsAuthorBlock--KAJ6z";
export var articleContents = "BlogPost-module--articleContents--jKzEN";
export var quote = "BlogPost-module--quote--2n5pu";
export var center = "BlogPost-module--center--2ZLOh";
export var right = "BlogPost-module--right--2-A8y";
export var left = "BlogPost-module--left--14msE";
export var wide = "BlogPost-module--wide--hF5NO";
export var bottomPattern = "BlogPost-module--bottomPattern--BworY";
export var lined = "BlogPost-module--lined--2fGTU";
export var sidelines = "BlogPost-module--sidelines--3yyHa";
export var stop = "BlogPost-module--stop--1iYHx";
export var dark = "BlogPost-module--dark--c35nU";
export var topLineHeader = "BlogPost-module--topLineHeader--2QUIm";
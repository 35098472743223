// extracted by mini-css-extract-plugin
export var relativeWrapper = "News-module--relativeWrapper--2j6CU";
export var decor = "News-module--decor--233-7";
export var hamburger = "News-module--hamburger--3HCPU";
export var units = "News-module--units--1BBoR";
export var reactCalendarMonthViewDays = "News-module--react-calendar__month-view__days--19p_E";
export var reactCalendarTile = "News-module--react-calendar__tile--3dZtO";
export var reactCalendarTileActive = "News-module--react-calendar__tile--active--3Vcfo";
export var container = "News-module--container--1m80Y";
export var newsSources = "News-module--newsSources--2FVEk";
export var logoList = "News-module--logoList--3tdu6";
export var logo = "News-module--logo--3QGyw";
export var articleQuote = "News-module--articleQuote--DpYOX";
export var tag = "News-module--tag--19doM";
export var link = "News-module--link--31cir";
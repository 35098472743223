// extracted by mini-css-extract-plugin
export var relativeWrapper = "BlogPost-module--relativeWrapper--1L2WA";
export var decor = "BlogPost-module--decor--8driY";
export var button = "BlogPost-module--button--2ZHm6";
export var container = "BlogPost-module--container--3KfTG";
export var backLinkContainer = "BlogPost-module--backLinkContainer--1YmcY";
export var backLink = "BlogPost-module--backLink--2qHUw";
export var lineAbove = "BlogPost-module--lineAbove--3vuBS";
export var newsHeader = "BlogPost-module--newsHeader--iB0jW";
export var newsFeatureMedia = "BlogPost-module--newsFeatureMedia--2Gsj-";
export var newsAuthorBlock = "BlogPost-module--newsAuthorBlock--2r_h2";
export var articleContents = "BlogPost-module--articleContents--QGrJE";
export var quote = "BlogPost-module--quote--2Wl4q";
export var center = "BlogPost-module--center--2z8Km";
export var right = "BlogPost-module--right--3usf5";
export var left = "BlogPost-module--left--103oe";
export var wide = "BlogPost-module--wide--2WHEu";
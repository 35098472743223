// extracted by mini-css-extract-plugin
export var relativeWrapper = "ArrowButton-module--relativeWrapper--23clG";
export var decor = "ArrowButton-module--decor--1jpCG";
export var hamburger = "ArrowButton-module--hamburger--1G-ed";
export var units = "ArrowButton-module--units--3jqsd";
export var reactCalendarMonthViewDays = "ArrowButton-module--react-calendar__month-view__days--3Wtfm";
export var reactCalendarTile = "ArrowButton-module--react-calendar__tile--1cVEU";
export var reactCalendarTileActive = "ArrowButton-module--react-calendar__tile--active--30BsK";
export var nextButton = "ArrowButton-module--nextButton--3VlVC";
export var small = "ArrowButton-module--small--1gUme";
export var content = "ArrowButton-module--content--31SFM";
export var arrow = "ArrowButton-module--arrow--1wucw";
export var position = "ArrowButton-module--position--3R3Uc";
export var backButton = "ArrowButton-module--backButton--1R5U6";
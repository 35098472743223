// extracted by mini-css-extract-plugin
export var relativeWrapper = "unit-module--relativeWrapper--1Idc5";
export var decor = "unit-module--decor--1z-6a";
export var bannerCarousel = "unit-module--bannerCarousel--Qgcys";
export var overlay = "unit-module--overlay--1i9IV";
export var nextButton = "unit-module--nextButton--15UJZ";
export var backButton = "unit-module--backButton--1P1ui";
export var carousel = "unit-module--carousel--Uwx2y";
export var image = "unit-module--image--3ztVi";
export var mobileCounter = "unit-module--mobileCounter--1DaD8";
export var inner = "unit-module--inner--3P_xq";
export var container = "unit-module--container--2MZ6q";
export var sideLine = "unit-module--sideLine--2YnGr";
export var line = "unit-module--line--3GV6o";
export var unitDetails = "unit-module--unitDetails--2Ttbv";
export var detailsContainer = "unit-module--detailsContainer--166ah";
export var mediaContainer = "unit-module--mediaContainer--2qlDD";
export var hidden = "unit-module--hidden--37W0F";
export var controls = "unit-module--controls--1JL0f";
export var detailsControls = "unit-module--detailsControls--2eKnN";
export var shareContainer = "unit-module--shareContainer--2_qSd";
export var priceContainer = "unit-module--priceContainer--32sIh";
export var applyNow = "unit-module--applyNow--6Q7-T";
export var sharing = "unit-module--sharing--STm-w";
export var mediaControls = "unit-module--mediaControls--mqjsP";
export var toggle = "unit-module--toggle--DWKM4";
export var active = "unit-module--active--2lXLs";
export var noTour = "unit-module--noTour--3gIjX";
export var amenitiesWrapper = "unit-module--amenitiesWrapper--1JSDJ";
import React from 'react';
import * as styles from './Banner.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useStaticQuery, graphql } from "gatsby";
import { usePreviewDocument } from '@utils/usePreviewDocument';
import classNames from 'classnames';

const Banner = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicHomepageSlider {
        nodes {
          data {
            headline
            intro
            photo {
              url
            }
          }
        }
      }
    }
  `);

  const previewedHomepageSlider = usePreviewDocument('homepage_slider');
  const slides = previewedHomepageSlider ? [ previewedHomepageSlider ] : data.allPrismicHomepageSlider.nodes;

  const slide = slides.length > 0 && slides[0].data;

  return (
    <section className={styles.banner}>
      <div className={styles.image} key={slide.headline} style={{ backgroundImage: `url(${slide.photo.url})` }}>
        <div className={styles.heading}>
          <span className={styles.company}>{slide.intro}</span>
          <span className={classNames(styles.company, styles.mobile)}>{slide.intro}</span>
          <span className={styles.title}>{slide.headline}</span>
        </div>
      </div>
    </section>
  );
};

export default Banner;

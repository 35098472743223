import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import InTheNewsBase from '@components/InTheNews';
import * as styles from './InTheNews.module.scss';

const InTheNews = () => {
  const data = useStaticQuery(graphql`
    query HomeInTheNews {
      allPrismicInTheNews(filter: {data: {page_selection: { in:["Gomes", "Homepage"]}}}) {
        nodes {
          dataRaw
          data {
            quote
            logo {
              target
              url
            }
            selected_logo {
              url
            }
            link {
              url
            }
            page_selection
          }
        }
      }
    }
  `);

  if (data.allPrismicInTheNews.nodes.length === 0) {
    return null;
  }

  const newsItemList = data.allPrismicInTheNews.nodes.map(e => ({ ...e.data, dataRaw: e.dataRaw }));

  return (
    <section id="news" className={styles.inTheNews}>
      <div className={styles.newsHeading}>
        <h2>HEADLINES</h2>
        <p>IN THE NEWS</p>
      </div>
      <InTheNewsBase styles={styles} newsItemList={newsItemList} />
    </section>
  );
};

export default InTheNews;

// extracted by mini-css-extract-plugin
export var relativeWrapper = "styles-module--relativeWrapper--3F53o";
export var decor = "styles-module--decor--1uQ9d";
export var hamburger = "styles-module--hamburger--1HI76";
export var isActive = "styles-module--is-active--nGze2";
export var hamburgerInner = "styles-module--hamburger-inner--bCVDV";
export var hamburgerBox = "styles-module--hamburger-box--NX_-X";
export var hamburgerSqueeze = "styles-module--hamburger--squeeze--3iQa7";
export var container = "styles-module--container--1veAo";
export var expanded = "styles-module--expanded--yRaM9";
export var mini = "styles-module--mini--q_HpC";
export var headerContent = "styles-module--headerContent--Agb9l";
export var contactContainer = "styles-module--contactContainer--BnaW_";
export var hidden = "styles-module--hidden--3uI13";
export var button = "styles-module--button--1Urjl";
export var mobile = "styles-module--mobile--3pcLw";
export var expandedContent = "styles-module--expandedContent--r58GY";
export var zoomedIn = "styles-module--zoomedIn--KNUmm";
export var menuList = "styles-module--menuList--2yiUL";
export var logoLink = "styles-module--logoLink--2AHRY";
export var gomesLogo = "styles-module--gomesLogo--1VoLU";
export var imageContainer = "styles-module--imageContainer--3tPvK";
export var image = "styles-module--image--BfNUA";
export var leftContainer = "styles-module--leftContainer--3bw_0";
export var logoSmall = "styles-module--logoSmall--ptUy_";
export var logoTiny = "styles-module--logoTiny--3tgx4";
export var lg = "styles-module--lg--2RlC_";
export var sm = "styles-module--sm--2n0t6";
export var iconContainer = "styles-module--iconContainer--3FcLX";
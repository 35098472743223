// extracted by mini-css-extract-plugin
export var relativeWrapper = "Amenities-module--relativeWrapper--fvbFa";
export var decor = "Amenities-module--decor--1xRvq";
export var container = "Amenities-module--container--1jYtL";
export var textContainer = "Amenities-module--textContainer--3F9v3";
export var carouselContainer = "Amenities-module--carouselContainer--2s4HT";
export var mobileTabs = "Amenities-module--mobileTabs--23A_c";
export var decorLine = "Amenities-module--decorLine--tq-XO";
export var tabs = "Amenities-module--tabs--2WCKt";
export var tab = "Amenities-module--tab--3zy8E";
export var selected = "Amenities-module--selected--ncK8J";
export var backgroundImage = "Amenities-module--backgroundImage--Mku3a";
export var foregroundImage = "Amenities-module--foregroundImage--1ue9o";
export var carousel = "Amenities-module--carousel--3uQKo";
export var arrow = "Amenities-module--arrow--G6hX4";
export var backButton = "Amenities-module--backButton--3WVIv";
export var nextButton = "Amenities-module--nextButton--2CUqL";
export var cardsWrapper = "Amenities-module--cardsWrapper--1fLRh";
export var card = "Amenities-module--card--1ScSE";
export var image = "Amenities-module--image--33x2C";
export var info = "Amenities-module--info--1JklP";
export var divider = "Amenities-module--divider--1j6XS";
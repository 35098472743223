import React from 'react';
import * as styles from './team.module.scss';
import classNames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import favicon from "@images/home/favicon.ico";

import Seo from '@components/seo';
import Layout from '@home/Layout';
import Banner from '@components/team/Banner';
import PhotoGallery from '@components/team/PhotoGallery';
import Quote from '@images/team/quote.svg';
import MobileQuote from '@images/team/quote-mobile.svg';
import { useState } from 'react';
import { usePreviewDocument } from '@utils/usePreviewDocument';

const Index = () => {
  const teamData = useStaticQuery(graphql`
    {
      allPrismicTeamMember {
        nodes {
          data {
            name
            position
            photo {
              thumbnails {
                xsmall {
                  url
                }
              }
            }
          }
        }
      }
      prismicTeamPage {
        data {
          headline
          content {
            raw
          }
        }
      }
    }
  `);
  const teamList = teamData.allPrismicTeamMember.nodes.map(e => e.data);
  const previewedTeamPage = usePreviewDocument('team_page')?.data;
  const pageData = previewedTeamPage || teamData.prismicTeamPage.data;
  const [expandTeam, setExpandTeam] = useState(false);

  const handleClick = () => setExpandTeam(true);

  return (
    <Layout>
      <Seo title="Team" icon={favicon} />
      <div className={styles.container}>
        <Banner />
        <section id="about">
          <p>ABOUT GOMES GROUP</p>
          <h2>{pageData.headline}</h2>
          <RichText render={pageData.content.raw} />
        </section>
        <PhotoGallery />
        <section id="placeholderQuote">
          <Quote />
          <MobileQuote className={styles.mobile} />
        </section>
        <section id="teamList">
          <div className={styles.content}>
            <p>MEET OUR TEAM</p>
            <h2>
              Our varied backgrounds and perspectives make us well-suited to help you on your housing journey. We are excited to get to know you!
            </h2>
            <div className={classNames(styles.teamList, { [styles.expanded]: expandTeam})}>
              {
                teamList.map((e, i) => (
                  <Link to={e.name.toLowerCase().replace(/\s/g, '-')} className={styles.teamItem} key={i}>
                    <img className={styles.image} src={e.photo.thumbnails.xsmall.url} alt="" />
                    <div className={styles.name}>
                      {e.name}
                    </div>
                  </Link>
                ))
              }
            </div>
            {
              !expandTeam &&
              <a className={styles.button} onClick={handleClick}>MEET MORE TEAM</a>
            }
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Index;

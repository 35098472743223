import React from 'react';
import * as styles from './PhotoGallery.module.scss';
import GLogo from '@images/home/glogo.svg';
import BigLogo from '@images/team/glogo.svg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.scss";
import Next from '@images/home/banner/next.svg';
import Prev from '@images/home/banner/prev.svg';
import { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { useState } from 'react';

const PhotoGallery = () => {
  const [index, setIndex] = useState(0);
  const galleryData = useStaticQuery(graphql`
    {
      prismicTeamPage {
        data {
          gallery_content
          gallery_headline
          gallery_images {
            image {
              url
            }
          }
        }
      }
    }
  `);

  const imageList = galleryData.prismicTeamPage.data.gallery_images;

  const updateThumbScroll = (idx) => {
    const listRef = carousel.current.thumbsRef.itemsListRef;
    const width = listRef.offsetWidth;

    const thumbnail = Array.from(listRef.children)[idx];
    const thumbVisible = (thumbnail.offsetLeft + thumbnail.offsetWidth) < (listRef.scrollLeft + width) &&
                         (thumbnail.offsetLeft + thumbnail.offsetWidth) > listRef.scrollLeft;

    if (!thumbVisible) {
      listRef.scrollTo({ left: thumbnail.offsetLeft + 5, behavior: 'smooth' });
    }
  };

  const carousel = useRef(null);
  const handleNextClick = () => {
    if (index >= (imageList.length - 1)) {
      return;
    }

    carousel.current.onClickNext();
    updateThumbScroll(index + 1);
  };
  const handlePrevClick = () => {
    if (index === 0) {
      return;
    }

    carousel.current.onClickPrev();
    updateThumbScroll(index - 1);
  };

  useEffect(()=> {
    updateThumbScroll(index);
  }, []);

  return (
    <section id="gallery">
      <div className={styles.left}>
        <BigLogo className={styles.logo} />
        <div className={styles.content}>
          <GLogo />
          <p>ABOUT GOMES PROPERTIES</p>
          <h2>{galleryData.prismicTeamPage.data.gallery_headline}</h2>
          <p>
            {galleryData.prismicTeamPage.data.gallery_content}
          </p>
        </div>
      </div>
      <div className={styles.galleryContainer}>
        <Carousel
          ref={carousel}
          className={styles.gallery}
          showIndicators={false}
          showStatus={false}
          renderThumbs={(children) => children.map((e, i) => (<div key={i} className={styles.thumb}>{e}</div>) )}
          thumbWidth={251}
          renderArrows={false}
          onChange={(idx) => setIndex(idx)}
        >
          {
            imageList.map((item, i) => (
              <img key={i} src={item.image.url} />
            ))
          }
        </Carousel >
        <Next onClick={handleNextClick} className={styles.next} />
        <Prev onClick={handlePrevClick} className={styles.prev} />
      </div>
    </section>
  );
};

export default PhotoGallery;

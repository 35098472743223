import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './ArrowButton.module.scss';
import ArrowButton from './ArrowButton';

function BackButton(props) {
  return (
    <div
      className={classNames(styles.backButton, props.className, {
        [styles.small]: props.small
      })}
      style={props.style}
      onClick={props.onClick}
    >
      <ArrowButton { ...props } />
    </div>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  small: PropTypes.bool
};

BackButton.defaultProps = {
  style: {},
  className: '',
  small: false
};

export default BackButton;

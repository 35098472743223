// extracted by mini-css-extract-plugin
export var relativeWrapper = "InTheNews-module--relativeWrapper--3EBhn";
export var decor = "InTheNews-module--decor--1jw2Z";
export var button = "InTheNews-module--button--1B-Kg";
export var inTheNews = "InTheNews-module--inTheNews--S_dp4";
export var container = "InTheNews-module--container--2crYn";
export var articleQuote = "InTheNews-module--articleQuote--3lY8D";
export var link = "InTheNews-module--link--1ALtN";
export var lineAbove = "InTheNews-module--lineAbove--AFrpr";
export var newsSources = "InTheNews-module--newsSources--2ctO3";
export var logoList = "InTheNews-module--logoList--3No2l";
export var logo = "InTheNews-module--logo--3Xcaj";
export var tag = "InTheNews-module--tag--1-oQI";
export var newsHeading = "InTheNews-module--newsHeading--30Jng";
// extracted by mini-css-extract-plugin
export var relativeWrapper = "TheNeighborhood-module--relativeWrapper--108Ly";
export var decor = "TheNeighborhood-module--decor--1FsM3";
export var container = "TheNeighborhood-module--container--2-2Tj";
export var carousel = "TheNeighborhood-module--carousel--2jTb7";
export var cardCarousel = "TheNeighborhood-module--cardCarousel--3tXs4";
export var cardWrapper = "TheNeighborhood-module--cardWrapper--2MnAC";
export var image = "TheNeighborhood-module--image--3ccQ9";
export var infoBlock = "TheNeighborhood-module--infoBlock--3PqXA";
export var arrow = "TheNeighborhood-module--arrow--2ji8r";
export var backButton = "TheNeighborhood-module--backButton--M9dtZ";
export var nextButton = "TheNeighborhood-module--nextButton--23T9u";
export var infoCarousel = "TheNeighborhood-module--infoCarousel--1yjhX";
export var infoWrapper = "TheNeighborhood-module--infoWrapper--1Pq_c";
export var info = "TheNeighborhood-module--info--BARn0";
export var name = "TheNeighborhood-module--name--vfDQJ";
export var description = "TheNeighborhood-module--description--10e3N";
export var address = "TheNeighborhood-module--address--3WLKV";
export var divider = "TheNeighborhood-module--divider--2HOyb";
export var directions = "TheNeighborhood-module--directions--f3yKx";
// extracted by mini-css-extract-plugin
export var relativeWrapper = "Header-module--relativeWrapper--1HQap";
export var decor = "Header-module--decor--1Ko9g";
export var button = "Header-module--button--DjmU2";
export var lineAbove = "Header-module--lineAbove--1grOp";
export var hamburger = "Header-module--hamburger--1ua2y";
export var isActive = "Header-module--is-active--2XVNp";
export var hamburgerInner = "Header-module--hamburger-inner--1bFZF";
export var hamburgerBox = "Header-module--hamburger-box--337Z1";
export var hamburgerSqueeze = "Header-module--hamburger--squeeze--1lozQ";
export var container = "Header-module--container--zBj3I";
export var expanded = "Header-module--expanded--aEh1E";
export var mini = "Header-module--mini--3Y74A";
export var headerContent = "Header-module--headerContent--2RhwI";
export var contactContainer = "Header-module--contactContainer--3FGWG";
export var hidden = "Header-module--hidden--3lq41";
export var mobile = "Header-module--mobile--3DfFj";
export var expandedContent = "Header-module--expandedContent--1Mvvb";
export var zoomedIn = "Header-module--zoomedIn--2BFrH";
export var menuList = "Header-module--menuList---aHN9";
export var logoLink = "Header-module--logoLink--3ttxW";
export var gomesLogo = "Header-module--gomesLogo--2JCKd";
export var imageContainer = "Header-module--imageContainer--Qplle";
export var image = "Header-module--image--imvMa";
export var logo = "Header-module--logo--2M-b5";
export var logoContainer = "Header-module--logoContainer--2c7g1";
export var socials = "Header-module--socials--1bWk7";
export var rightContainer = "Header-module--rightContainer--1fkeD";
export var residentLink = "Header-module--residentLink--chxTa";
export var backgroundRectangle = "Header-module--backgroundRectangle--3PS6M";
export var active = "Header-module--active--cgF_z";
import { usePrismicPreviewContext } from "gatsby-plugin-prismic-previews";

/**
 * Hook for retrieving the previewed document from the prismic preview context.
 * @param   {string}          documentType    the type of prismic document
 * @returns {{data: Object}}  a prismic node
 */
export const usePreviewDocument = (documentType) => {
  if (!documentType) return null;

  const [ previewState ] = usePrismicPreviewContext();
  const [ previewDocument ] = Object.values(previewState?.nodes).filter(node => node.type === documentType);
  
  return previewDocument;
};

// extracted by mini-css-extract-plugin
export var relativeWrapper = "Footer-module--relativeWrapper--3Es2m";
export var decor = "Footer-module--decor--3dZhD";
export var hamburger = "Footer-module--hamburger--14Mem";
export var units = "Footer-module--units--6W9xQ";
export var reactCalendarMonthViewDays = "Footer-module--react-calendar__month-view__days--3KcZh";
export var reactCalendarTile = "Footer-module--react-calendar__tile--1Il7x";
export var reactCalendarTileActive = "Footer-module--react-calendar__tile--active--3D7KH";
export var container = "Footer-module--container--1eaYn";
export var row = "Footer-module--row--2f1KK";
export var lastRow = "Footer-module--lastRow--15Ztq";
export var wrapper = "Footer-module--wrapper--2sM-1";
export var social = "Footer-module--social--34UY9";
export var logo = "Footer-module--logo--2aCGg";
export var contact = "Footer-module--contact--YqhIS";
export var copyright = "Footer-module--copyright--2_OtJ";
export var programs = "Footer-module--programs--3JYcE";
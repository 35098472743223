import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { newsStylesFor as stylesFor } from '@utils/previewResolver';
import { usePreviewDocument } from '@utils/usePreviewDocument';

function News({
  styles: liveStyles,
  containerStyle,
  newsItemList,
  seeMoreLink,
}) {
  const [selectedIndex, setIndex] = useState(0);
  const handleLogoClick = e => {
    const newIndex = parseInt(e.currentTarget.dataset.index);
    setIndex(newIndex);
  };

  const previewedNewsItem = usePreviewDocument('in_the_news')?.data;

  const newsItems = newsItemList || [previewedNewsItem];

  const styles = liveStyles || stylesFor[previewedNewsItem.page_selection]; // fallback for previews

  return (
    <div
      className={styles.relativeWrapper}
      style={{ padding: 0, marginTop: '190px' }}
    >
      <div className={styles.container} style={containerStyle} id="news">
        <div className={styles.newsSources}>
          <div className={styles.logoList}>
            {newsItems.map((item, index) => {
              const isSelected = selectedIndex === index;
              const logoUrl = isSelected
                ? item.selected_logo.url
                : item.logo.url;

              return (
                <img
                  key={index}
                  data-index={index}
                  className={styles.logo}
                  onClick={handleLogoClick}
                  src={logoUrl}
                />
              );
            })}
          </div>
          {seeMoreLink && (
            <Link
              to={seeMoreLink}
              className={styles.link}
              style={{
                width: '163px',
                textAlign: 'center',
                marginLeft: '18.5px',
              }}
            >
              SEE MORE NEWS
            </Link>
          )}
        </div>
        <div className={styles.articleQuote}>
          <span className={styles.tag}>IN THE NEWS</span>
          <p>{newsItems[selectedIndex]?.quote}</p>
          <Link
            to={
              newsItems[selectedIndex]?.link?.url ||
              newsItems[selectedIndex]?.external_link?.url
            }
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            READ MORE
          </Link>
        </div>
      </div>
    </div>
  );
}

News.propTypes = {
  containerStyle: PropTypes.object,
  newsItemList: PropTypes.array,
  styles: PropTypes.object,
  seeMoreLink: PropTypes.string,
};

News.defaultProps = {
  containerStyle: {},
  newsItemList: null,
  styles: null,
  seeMoreLink: 'news',
};

export default News;
